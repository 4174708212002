import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
  InputBase,
  Switch,
  FormControl,
  Select,
  Snackbar,
  Alert,
  MenuItem,
  Checkbox,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileCopy } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Editor } from "@tinymce/tinymce-react";
import DragAndDropList from "./DragAndDropList";
import {
  createChallenge,
  updateChallenge,
  getChallengePacksByProgramId,
  getPrograms,
  getChallenges,
} from "../helper/admin.helper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const initialGame = {
  Pairs: {
    timeToSolve: 2,
    matrixChoice: 2,
    timeUpMessage: "",
    successMessage: "",
  },
  Poirot: {
    timeToSolve: 2,
    cluesCount: 4,
    clues: [],
    correctAnswer: "",
    displayTime: "",
    timeUpMessage: "",
    successMessage: "",
  },
};

export default function CreateChallenge({
  onFinish,
  isEdit,
  editData,
  freePacks,
}) {
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [challengePacks, setChallengePacks] = useState(freePacks);
  const [otherChallenges, setOtherChallenges] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    showDescription: false,
    isActive: true,
    challengeBGImage: "",
    isiText: "",
    optionalContent: "",
    isStikyISI: false,
    challengePackId: 0,
    challengePack: "",
    program: 0,
    challengeType: "Quiz",
    shortUrl: "",
    category: "",
    bannerAd: "",
    interstitialAd: "",
    quizzes: [
      {
        quizQuestion: "",
        answerChoice1: "",
        answerChoice2: "",
        answerChoice3: "",
        answerChoice4: "",
        answerChoice5: "",
      },
    ],
    gameSection: initialGame.Pairs,
    answerFlags: [""],
    successMessage: "",
    successMessageImage: "",
    incorrectMessage: "",
    incorrectMessageImage: "",
    challengeImage: "",
    images: [],
    thumbnailImage: "",
    moreUrl: "",
    plLink: "",
    isiLink: "",
    plOrISIColor: "",
    brandLogo: "",
    indicationInfoText: "",
    indicationColor: "",
    companyInfoText: "",
    legalNoticeLink: "",
    privacyPolicyLink: "",
    companyLogo: "",
    displayResult: false,
    additionalChallenge: "",
    lnAndPpColor: "",
  });

  useEffect(() => {
    getPrograms().then((response) => {
      if (response.data) {
        const { data } = response;
        const selectData = data.programs.map((p) => {
          return { id: p.id, name: p.name };
        });

        setPrograms(selectData);
      }
    });

    getChallenges().then((response) => {
      if (response.data) {
        const { data } = response;
        if (data.challenges.length) {
          if (isEdit && editData) {
            setOtherChallenges(
              data.challenges
                .filter((c) => c.id !== editData.id)
                .map((challange) => {
                  return challange.name;
                })
            );
          } else {
            setOtherChallenges(
              data.challenges.map((challange) => {
                return challange.name;
              })
            );
          }
        }
      }
    });
  }, [editData]);

  useEffect(() => {
    if (editData) {
      setFormData({
        name: editData.name,
        description: editData.description,
        showDescription: editData.show_description === "1",
        isActive: editData.is_active === "1",
        challengeBGImage: editData.challenge_bg_image ?? "",
        isiText: editData.isi ?? "",
        optionalContent: editData.optional_content ?? "",
        isStikyISI: editData.stiky_isi === "1",
        challengePack: editData.challenge_pack_name,
        challengePackId: editData.challenge_pack_id ?? 0,
        program: editData.program_id ?? 0,
        challengeType: editData.type,
        shortUrl: editData.short_url ?? "",
        category: editData.category ?? "",
        bannerAd: editData.banner_ad_id ?? "",
        interstitialAd: editData.interstitial_ad ?? "",
        quizzes: editData.quizzes
          ? JSON.parse(editData.quizzes)
          : [
              {
                quizQuestion: "",
                answerChoice1: "",
                answerChoice2: "",
                answerChoice3: "",
                answerChoice4: "",
                answerChoice5: "",
              },
            ],
        answerFlags: editData.answer_flags
          ? JSON.parse(editData.answer_flags)
          : [""],
        gameSection: editData.gameSection
          ? JSON.parse(editData.gameSection)
          : initialGame["Pairs"],
        successMessage: editData.success_message ?? "",
        successMessageImage: editData.success_message_image ?? "",
        incorrectMessage: editData.incorrect_message ?? "",
        incorrectMessageImage: editData.incorrect_message_image ?? "",
        challengeImage: editData.image ?? "",
        images: editData.images ? JSON.parse(editData.images) : [],
        thumbnailImage: editData.thumbnail_image ?? "",
        moreUrl: editData.more_url ?? "",
        plLink: editData.pi_link ?? "",
        isiLink: editData.isi_link ?? "",
        plOrISIColor: editData.pl_color ?? "",
        brandLogo: editData.brand_logo ?? "",
        indicationInfoText: editData.indication_info ?? "",
        companyInfoText: editData.company_info ?? "",
        legalNoticeLink: editData.legal_notice_link ?? "",
        privacyPolicyLink: editData.privacy_policy_link ?? "",
        companyLogo: editData.company_logo ?? "",
        displayResult: editData.display_results === "1",
        additionalChallenge: editData.additional_challenges ?? "",
        indicationColor: editData.indication_color ?? "",
        lnAndPpColor: editData.ln_pp_color ?? "",
      });

      getChallengePacksByProgramId(editData.program_id).then((response) => {
        if (response.data) {
          const { data } = response;
          const selectData = data.packs.map((p) => {
            return { id: p.id, name: p.name };
          });
          setChallengePacks(selectData);
        }
      });
    }
  }, [editData]);

  const handleCopy = (type) => {
    const baseUrl = `https://next.pocngo.com`;
    let url = "";

    if (type === "long") {
      url = `${baseUrl}${
        formData.program && formData.program !== 0 ? "/program" : ""
      }${
        formData.challengePackId && formData.challengePackId !== 0
          ? "/pack"
          : ""
      }/challenge/${encodeURIComponent(formData.name)}?challengeid=${
        editData ? editData.id : ""
      }`;
    } else {
      if (formData.shortUrl && formData.shortUrl !== "") {
        url = `${baseUrl}${
          formData.program && formData.program !== 0 ? "/program" : ""
        }${
          formData.challengePackId && formData.challengePackId !== 0
            ? "/pack"
            : ""
        }/challenge/${encodeURIComponent(formData.shortUrl)}?challengeid=${
          editData ? editData.id : ""
        }`;
      } else {
        setOpen(true);
        setResponse({
          status: false,
          message: "This Challenge doesn't have a short URL",
        });
        return; // Exit if no short URL
      }
    }

    navigator.clipboard.writeText(url);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  console.log(formData.images);
  const handleSubmit = () => {
    if (formData.name === "" || formData.description === "") {
      setOpen(true);
      setResponse({
        status: false,
        message: "Please fill out all required fields",
      });

      return;
    }

    if (formData.challengeType === "Pairs") {
      const maxUploadCount = 4 + formData.gameSection.matrixChoice * 2;
      if (formData.images.length !== maxUploadCount) {
        setOpen(true);
        setResponse({
          status: false,
          message: `You must upload ${maxUploadCount} images for Pairs game.`,
        });
        return;
      }
    }

    if (formData.challengeType === "Poirot") {
      if (formData.gameSection.cluesCount !== formData.images.length) {
        setOpen(true);
        setResponse({
          status: false,
          message: `You must upload ${formData.gameSection.cluesCount} images for Poirot game.`,
        });
        return;
      }

      if (
        formData.gameSection.cluesCount !== formData.gameSection.clues.length
      ) {
        setOpen(true);
        setResponse({
          status: false,
          message: `You must input ${formData.gameSection.cluesCount} clue texts for Poirot game.`,
        });
        return;
      }
    }

    const payload = {
      ...formData,
      quizzes: JSON.stringify(formData.quizzes),
      answerFlags: JSON.stringify(formData.answerFlags),
      gameSection: JSON.stringify(formData.gameSection),
    };

    if (isEdit) {
      updateChallenge(payload, editData.id)
        .then((data) => {
          if (data.status) {
            setResponse({
              status: true,
              message: `${formData.name} Challenge is updated successfully!`,
            });
            setOpen(true);
            handleCancel();
          } else {
            setResponse({
              status: false,
              message: data.message,
            });
            setOpen(true);
          }
        })
        .catch((errorMessage) => {
          setResponse({
            status: false,
            message: errorMessage,
          });
          setOpen(true);
        });
    } else {
      createChallenge(payload)
        .then((data) => {
          if (data.status) {
            setResponse({
              status: true,
              message: `${formData.name} Challenge is created successfully!`,
            });
            setOpen(true);
            handleCancel();
          } else {
            setResponse({
              status: false,
              message: data.message,
            });
            setOpen(true);
          }
        })
        .catch((errorMessage) => {
          setResponse({
            status: false,
            message: errorMessage,
          });
          setOpen(true);
        });
    }
  };

  const handleFileChange = (event, property) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, [property]: file });
    }
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const images = [...formData.images];
      images[index] = {
        file: file,
        preview: URL.createObjectURL(file),
      };
      setFormData({
        ...formData,
        images: images,
      });
    }
  };

  console.log(formData.images);

  const handleImagesUpload = (event) => {
    const maxUploadCount = 4 + formData.gameSection.matrixChoice * 2;
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    const images = [...formData.images, ...newImages];
    if (images.length > maxUploadCount) {
      setOpen(true);
      setResponse({
        status: false,
        message: `You can not upload over ${maxUploadCount} images.`,
      });
      return;
    }
    setFormData({
      ...formData,
      images: images,
    });
  };

  const handleImageRemove = (imageToRemove) => {
    if (imageToRemove.preview) {
      const images = formData.images.filter(
        (image) => image.preview !== imageToRemove.preview
      );
      setFormData({
        ...formData,
        images: images,
      });
      URL.revokeObjectURL(imageToRemove.preview);
    } else {
      const images = formData.images.filter((image) => image !== imageToRemove);
      setFormData({
        ...formData,
        images: images,
      });
    }
  };

  const handleChangeProgram = (e) => {
    setFormData({ ...formData, program: e.target.value });

    getChallengePacksByProgramId(e.target.value).then((response) => {
      if (response.data) {
        const { data } = response;
        const selectData = data.packs.map((p) => {
          return { id: p.id, name: p.name };
        });

        setChallengePacks(selectData);
        setFormData({
          ...formData,
          program: e.target.value,
          challengePackId: selectData.length !== 0 ? selectData[0].id : 0,
          challengePack: selectData.length !== 0 ? selectData[0].name : "",
        });
      }
    });
  };

  const handleCancel = () => {
    onFinish();

    setTimeout(() => {
      setFormData({
        name: "",
        description: "",
        showDescription: false,
        challengeBGImage: "",
        isiText: "",
        optionalContent: "",
        isStikyISI: false,
        challengePackId: 0,
        challengePack: "",
        program: 0,
        challengeType: "Quiz",
        shortUrl: "",
        category: "",
        bannerAd: "",
        interstitialAd: "",
        quizzes: [
          {
            quizQuestion: "",
            answerChoice1: "",
            answerChoice2: "",
            answerChoice3: "",
            answerChoice4: "",
            answerChoice5: "",
          },
        ],
        answerFlags: [""],
        successMessage: "",
        successMessageImage: "",
        incorrectMessage: "",
        incorrectMessageImage: "",
        challengeImage: "",
        thumbnailImage: "",
        moreUrl: "",
        plLink: "",
        isiLink: "",
        plOrISIColor: "",
        brandLogo: "",
        indicationInfoText: "",
        companyInfoText: "",
        legalNoticeLink: "",
        privacyPolicyLink: "",
        companyLogo: "",
        displayResult: false,
        additionalChallenge: "",
        indicationColor: "",
        lnAndPpColor: "",
      });
    }, 1000);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={response.status ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {response.message}
          </Alert>
        </Snackbar>
        <Typography sx={{ fontWeight: 700, color: "#6f6a6a" }}>
          {isEdit ? "Edit Challenge" : "Create New Challenge"}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={handleSubmit}>
            {isEdit ? "Save Challenge" : "Create Challenge"}
          </Button>
          <Button
            variant="outlined"
            sx={{ bgcolor: "grey[300]" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Challenge Name*:
              </Typography>
              <InputBase
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{ fontSize: "16px", color: "#737171" }}
                >
                  Description*:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "16px",
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    Show Description:
                  </Typography>
                  <Switch
                    checked={formData.showDescription}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        showDescription: e.target.checked,
                      })
                    }
                  />
                </Box>
              </Box>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.description}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, description: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontSize: "16px", color: "#737171" }}
                  >
                    ISI Text:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{
                        fontSize: "16px",
                        color: "#737171",
                        textAlign: "left",
                        minWidth: "200px",
                      }}
                    >
                      Sticky ISI:
                    </Typography>
                    <Switch
                      checked={formData.isStikyISI}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          isStikyISI: e.target.checked,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.isiText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, isiText: content })
                }
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Program:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.program}
                  onChange={handleChangeProgram}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  {programs.map((program, index) => {
                    return (
                      <MenuItem value={program.id} key={`program-${index}`}>
                        {program.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Challenge Pack:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.challengePackId}
                  onChange={(e) => {
                    const pack = challengePacks.find(
                      (p) => p.id === e.target.value
                    );
                    setFormData({
                      ...formData,
                      challengePackId: e.target.value,
                      challengePack: pack ? pack.name : "",
                    });
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  {challengePacks.map((pack, index) => {
                    return (
                      <MenuItem value={pack.id} key={`challenge-pack-${index}`}>
                        {pack.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Challenge Type:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.challengeType}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      challengeType: e.target.value,
                      gameSection: initialGame[e.target.value],
                    })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Quiz">Quiz</MenuItem>
                  <MenuItem value="Mosaic">Mosaic</MenuItem>
                  <MenuItem value="Slider">Slider</MenuItem>
                  <MenuItem value="Unfuzz">Unfuzz</MenuItem>
                  <MenuItem value="Poirot">Poirot</MenuItem>
                  <MenuItem value="Pairs">Pairs</MenuItem>
                  <MenuItem value="Pop the Balloon">Pop the Balloon</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Challenge Short URL:
              </Typography>
              <Box display="flex" width="100%">
                <InputBase
                  type="text"
                  value={formData.shortUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, shortUrl: e.target.value })
                  }
                  sx={{
                    border: "1px solid #ccc",
                    padding: "3px 10px",
                    color: "#000",
                    flex: 1,
                  }}
                ></InputBase>
                <Button
                  variant="contained"
                  endIcon={<FileCopy />}
                  size="small"
                  onClick={() => handleCopy("long")}
                >
                  Long
                </Button>
                <Button
                  variant="contained"
                  endIcon={<FileCopy />}
                  size="small"
                  onClick={() => handleCopy("short")}
                >
                  Short
                </Button>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={2000}
                  onClose={handleCloseSnackbar}
                  message="Copied to clipboard!"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Category:
              </Typography>
              <InputBase
                type="text"
                value={formData.category}
                onChange={(e) =>
                  setFormData({ ...formData, category: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Banner Ad:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.bannerAd}
                  onChange={(e) =>
                    setFormData({ ...formData, bannerAd: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Interstitial Ad:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.interstitialAd}
                  onChange={(e) =>
                    setFormData({ ...formData, interstitialAd: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ textAlign: "left" }}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem> */}
                  {/* <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Challenge BG Image: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (1920 x 750)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "challengeBGImage")}
                />
              </Button>
              {formData.challengeBGImage.name ||
                (formData.challengeBGImage && (
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      color: "green",
                      fontSize: "16px",
                    }}
                  >
                    {formData.challengeBGImage.name ||
                      formData.challengeBGImage}
                  </Typography>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Active:
              </Typography>
              <Switch
                checked={formData.isActive}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isActive: e.target.checked,
                  })
                }
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Legal Notice Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.legalNoticeLink}
                onChange={(e) =>
                  setFormData({ ...formData, legalNoticeLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Privacy Policy Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.privacyPolicyLink}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    privacyPolicyLink: e.target.value,
                  })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                LN/PP Link Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.lnAndPpColor}
                onChange={(e) =>
                  setFormData({ ...formData, lnAndPpColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                PI Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.plLink}
                onChange={(e) =>
                  setFormData({ ...formData, plLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                ISI Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.isiLink}
                onChange={(e) =>
                  setFormData({ ...formData, isiLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                PI/ISI Link Text Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.plOrISIColor}
                onChange={(e) =>
                  setFormData({ ...formData, plOrISIColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Indication Info Text:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.on("keydown", function (e) {
                      if (
                        editor.getContent({ format: "text" }).length >= 1000 &&
                        e.keyCode !== 8 &&
                        e.keyCode !== 46 &&
                        !e.ctrlKey
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    });
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.indicationInfoText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, indicationInfoText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Indication Border Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.indicationColor}
                onChange={(e) =>
                  setFormData({ ...formData, indicationColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Brand Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (550 x 300)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "brandLogo")}
                />
              </Button>
              {formData.brandLogo.name ||
                (formData.brandLogo && (
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      color: "green",
                      fontSize: "16px",
                    }}
                  >
                    {formData.brandLogo.name || formData.brandLogo}
                  </Typography>
                ))}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Company Info Text:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                    "wordcount",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  wordcount_max_chars: 1000,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.on("keyup", function () {
                      if (editor.getContent({ format: "text" }).length > 1000) {
                        editor.setContent(
                          editor.getContent({ format: "text" }).substr(0, 1000)
                        );
                        setOpen(true);
                        setResponse({
                          status: false,
                          message:
                            "You have reached the maximum allowed characters.",
                        });
                      }
                    });
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.companyInfoText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, companyInfoText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Company Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (550 x 300)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "companyLogo")}
                />
              </Button>
              {formData.companyLogo.name ||
                (formData.companyLogo && (
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      color: "green",
                      fontSize: "16px",
                    }}
                  >
                    {formData.companyLogo.name || formData.companyLogo}
                  </Typography>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Optional Content:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.optionalContent}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, optionalContent: content })
                }
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={12}>
          <Stack direction="column" spacing={2}>
            <Typography
              component={"label"}
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                color: "#737171",
                textAlign: "left",
                minWidth: "200px",
              }}
            >
              Additional Challenges Section
            </Typography>
            <DragAndDropList
              isEdit={isEdit}
              challenges={otherChallenges}
              initValue={editData ? editData.additional_challenges : null}
              onSendSequence={(sequence) =>
                setFormData({ ...formData, additionalChallenge: sequence })
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {formData.challengeType === "Quiz" && (
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 3, sm: 5, md: 10 }}
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} md={10}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Quiz Section
                </Typography>
              </Box>
            </Box>
          </Grid>
          {formData.quizzes &&
            formData.quizzes.map((quiz, index) => (
              <>
                <Grid item xs={12} md={10}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "flex-start",
                      gap: 1,
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{
                        fontSize: "16px",
                        color: "#737171",
                        textAlign: "left",
                        minWidth: "200px",
                      }}
                    >
                      Quiz{" "}
                      {formData.quizzes.length !== 1
                        ? formData.quizzes.length - index
                        : ""}{" "}
                      Question (HTML):
                    </Typography>
                    <Editor
                      apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                      rows="5"
                      init={{
                        width: "100%",
                        height: 200,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                          "textcolor colorpicker textpattern",
                          "fontselect fontsize",
                          "code",
                        ],
                        toolbar:
                          "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                        menubar: false,
                        toolbar_mode: "wrap",
                        branding: false,
                        mergetags_list: [
                          { value: "First.Name", title: "First Name" },
                          { value: "Email", title: "Email" },
                        ],
                        setup: function (editor) {
                          editor.ui.registry.addButton("toggleHtml", {
                            text: "Toggle HTML",
                            onAction: function () {
                              // Toggle between visual and code modes
                              if (editor.plugins.code) {
                                editor.execCommand("mceCodeEditor");
                              }
                            },
                          });
                        },
                        ai_request: (request, respondWith) =>
                          respondWith.string(() =>
                            Promise.reject("See docs to implement AI Assistant")
                          ),
                      }}
                      value={quiz.quizQuestion}
                      onEditorChange={
                        (content, editor) => {
                          const newQuizzes = [...formData.quizzes];
                          newQuizzes[index].quizQuestion = content;
                          setFormData({ ...formData, quizzes: newQuizzes });
                        }
                        // setFormData({ ...formData, quizQuestion: content })
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{
                          fontSize: "16px",
                          color: "#737171",
                          textAlign: "left",
                          minWidth: "200px",
                        }}
                      >
                        Answer Choice 1:
                      </Typography>
                      <InputBase
                        type="text"
                        value={quiz.answerChoice1}
                        onChange={
                          (e) => {
                            const newQuizzes = [...formData.quizzes];
                            newQuizzes[index].answerChoice1 = e.target.value;
                            setFormData({ ...formData, quizzes: newQuizzes });
                          }
                          // setFormData({
                          //   ...formData,
                          //   answerChoice1: e.target.value,
                          // })
                        }
                        sx={{
                          border: "1px solid #ccc",
                          padding: "3px 10px",
                          color: "#000",
                          flex: 1,
                        }}
                      ></InputBase>
                      <Checkbox
                        checked={
                          formData.answerFlags &&
                          quiz.answerChoice1 &&
                          formData.answerFlags[index] === quiz.answerChoice1
                        }
                        onChange={(e) => {
                          const newAnswers = [...formData.answerFlags];
                          newAnswers[index] = e.target.checked
                            ? quiz.answerChoice1
                            : "";
                          setFormData({ ...formData, answerFlags: newAnswers });
                        }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{
                          fontSize: "16px",
                          color: "#737171",
                          textAlign: "left",
                          minWidth: "200px",
                        }}
                      >
                        Answer Choice 3:
                      </Typography>
                      <InputBase
                        type="text"
                        value={quiz.answerChoice3}
                        onChange={(e) => {
                          const newQuizzes = [...formData.quizzes];
                          newQuizzes[index].answerChoice3 = e.target.value;
                          setFormData({ ...formData, quizzes: newQuizzes });
                        }}
                        sx={{
                          border: "1px solid #ccc",
                          padding: "3px 10px",
                          color: "#000",
                          flex: 1,
                        }}
                      ></InputBase>
                      <Checkbox
                        checked={
                          formData.answerFlags &&
                          quiz.answerChoice3 &&
                          formData.answerFlags[index] === quiz.answerChoice3
                        }
                        onChange={(e) => {
                          const newAnswers = [...formData.answerFlags];
                          newAnswers[index] = e.target.checked
                            ? quiz.answerChoice3
                            : "";
                          setFormData({ ...formData, answerFlags: newAnswers });
                        }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{
                          fontSize: "16px",
                          color: "#737171",
                          textAlign: "left",
                          minWidth: "200px",
                        }}
                      >
                        Answer Choice 5:
                      </Typography>
                      <InputBase
                        type="text"
                        value={quiz.answerChoice5}
                        onChange={(e) => {
                          const newQuizzes = [...formData.quizzes];
                          newQuizzes[index].answerChoice5 = e.target.value;
                          setFormData({ ...formData, quizzes: newQuizzes });
                        }}
                        sx={{
                          border: "1px solid #ccc",
                          padding: "3px 10px",
                          color: "#000",
                          flex: 1,
                        }}
                      ></InputBase>
                      <Checkbox
                        checked={
                          formData.answerFlags &&
                          quiz.answerChoice5 &&
                          formData.answerFlags[index] === quiz.answerChoice5
                        }
                        onChange={(e) => {
                          const newAnswers = [...formData.answerFlags];
                          newAnswers[index] = e.target.checked
                            ? quiz.answerChoice5
                            : "";
                          setFormData({ ...formData, answerFlags: newAnswers });
                        }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{
                          fontSize: "16px",
                          color: "#737171",
                          textAlign: "left",
                          minWidth: "200px",
                        }}
                      >
                        Answer Choice 2:
                      </Typography>
                      <InputBase
                        type="text"
                        value={quiz.answerChoice2}
                        onChange={(e) => {
                          const newQuizzes = [...formData.quizzes];
                          newQuizzes[index].answerChoice2 = e.target.value;
                          setFormData({ ...formData, quizzes: newQuizzes });
                        }}
                        sx={{
                          border: "1px solid #ccc",
                          padding: "3px 10px",
                          color: "#000",
                          flex: 1,
                        }}
                      ></InputBase>
                      <Checkbox
                        checked={
                          formData.answerFlags &&
                          quiz.answerChoice2 &&
                          formData.answerFlags[index] === quiz.answerChoice2
                        }
                        onChange={(e) => {
                          const newAnswers = [...formData.answerFlags];
                          newAnswers[index] = e.target.checked
                            ? quiz.answerChoice2
                            : "";
                          setFormData({ ...formData, answerFlags: newAnswers });
                        }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{
                          fontSize: "16px",
                          color: "#737171",
                          textAlign: "left",
                          minWidth: "200px",
                        }}
                      >
                        Answer Choice 4:
                      </Typography>
                      <InputBase
                        type="text"
                        value={quiz.answerChoice4}
                        onChange={(e) => {
                          const newQuizzes = [...formData.quizzes];
                          newQuizzes[index].answerChoice4 = e.target.value;
                          setFormData({ ...formData, quizzes: newQuizzes });
                        }}
                        sx={{
                          border: "1px solid #ccc",
                          padding: "3px 10px",
                          color: "#000",
                          flex: 1,
                        }}
                      ></InputBase>
                      <Checkbox
                        checked={
                          formData.answerFlags &&
                          quiz.answerChoice4 &&
                          formData.answerFlags[index] === quiz.answerChoice4
                        }
                        onChange={(e) => {
                          const newAnswers = [...formData.answerFlags];
                          newAnswers[index] = e.target.checked
                            ? quiz.answerChoice4
                            : "";
                          setFormData({ ...formData, answerFlags: newAnswers });
                        }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </>
            ))}

          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  gap: 1,
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Success Message (HTML):
                </Typography>
                <Editor
                  apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                  rows="5"
                  init={{
                    width: "100%",
                    height: 200,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                      "textcolor colorpicker textpattern",
                      "fontselect fontsize",
                      "code",
                    ],
                    toolbar:
                      "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                    menubar: false,
                    toolbar_mode: "floating",
                    branding: false,
                    mergetags_list: [
                      { value: "First.Name", title: "First Name" },
                      { value: "Email", title: "Email" },
                    ],
                    setup: function (editor) {
                      editor.ui.registry.addButton("toggleHtml", {
                        text: "Toggle HTML",
                        onAction: function () {
                          // Toggle between visual and code modes
                          if (editor.plugins.code) {
                            editor.execCommand("mceCodeEditor");
                          }
                        },
                      });
                    },
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                  }}
                  value={formData.successMessage}
                  onEditorChange={(content, editor) =>
                    setFormData({ ...formData, successMessage: content })
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Success Message Image: <br />
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    (750 x 750)
                  </Typography>
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, "successMessageImage")}
                  />
                </Button>
                {formData.successMessageImage.name ||
                  (formData.successMessageImage && (
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.successMessageImage.name ||
                        formData.successMessageImage}
                    </Typography>
                  ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Thumbnail Image: <br />
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    (300 x 300)
                  </Typography>
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, "thumbnailImage")}
                  />
                </Button>
                {formData.thumbnailImage.name ||
                  (formData.thumbnailImage && (
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.thumbnailImage.name || formData.thumbnailImage}
                    </Typography>
                  ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Display Results Stats Message:
                </Typography>
                <Switch
                  checked={formData.displayResult}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      displayResult: e.target.checked,
                    })
                  }
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  gap: 1,
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Incorrect Message (HTML):
                </Typography>
                <Editor
                  apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                  rows="5"
                  init={{
                    width: "100%",
                    height: 200,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                      "textcolor colorpicker textpattern",
                      "fontselect fontsize",
                      "code",
                    ],
                    toolbar:
                      "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                    menubar: false,
                    toolbar_mode: "floating",
                    branding: false,
                    mergetags_list: [
                      { value: "First.Name", title: "First Name" },
                      { value: "Email", title: "Email" },
                    ],
                    setup: function (editor) {
                      editor.ui.registry.addButton("toggleHtml", {
                        text: "Toggle HTML",
                        onAction: function () {
                          // Toggle between visual and code modes
                          if (editor.plugins.code) {
                            editor.execCommand("mceCodeEditor");
                          }
                        },
                      });
                    },
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                  }}
                  value={formData.incorrectMessage}
                  onEditorChange={(content, editor) =>
                    setFormData({ ...formData, incorrectMessage: content })
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Incorrect Message Image: <br />
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    (750 x 750)
                  </Typography>
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      handleFileChange(e, "incorrectMessageImage")
                    }
                  />
                </Button>
                {formData.incorrectMessageImage.name ||
                  (formData.incorrectMessageImage && (
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.incorrectMessageImage.name ||
                        formData.incorrectMessageImage}
                    </Typography>
                  ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Challenge Image or Video: <br />
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    (750 x 750)
                  </Typography>
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*,video/*"
                    onChange={(e) => handleFileChange(e, "challengeImage")}
                  />
                </Button>
                {formData.challengeImage.name ||
                  (formData.challengeImage && (
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.challengeImage.name || formData.challengeImage}
                    </Typography>
                  ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Click More URL:
                </Typography>
                <InputBase
                  type="text"
                  value={formData.moreUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, moreUrl: e.target.value })
                  }
                  sx={{
                    border: "1px solid #ccc",
                    padding: "3px 10px",
                    color: "#000",
                    flex: 1,
                  }}
                ></InputBase>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )}
      {formData.challengeType === "Pairs" && (
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 3, sm: 5, md: 10 }}
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} md={10}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Pairs Section
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Time to Solve Clue (min):
                </Typography>
                <InputBase
                  type="text"
                  value={formData.gameSection.timeToSolve}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      gameSection: {
                        ...formData.gameSection,
                        timeToSolve: e.target.value,
                      },
                    });
                  }}
                  sx={{
                    border: "1px solid #ccc",
                    padding: "3px 10px",
                    color: "#000",
                  }}
                ></InputBase>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Default Image:
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, "challengeImage")}
                  />
                </Button>
                {(formData.challengeImage.name || formData.challengeImage) && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.challengeImage.name || formData.challengeImage}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          challengeImage: "",
                        });
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  gap: 1,
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Time Up Message (HTML):
                </Typography>
                <Editor
                  apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                  rows="5"
                  init={{
                    width: "100%",
                    height: 250,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                      "textcolor colorpicker textpattern",
                      "fontselect fontsize",
                      "code",
                    ],
                    toolbar:
                      "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                    menubar: false,
                    toolbar_mode: "wrap",
                    branding: false,
                    mergetags_list: [
                      { value: "First.Name", title: "First Name" },
                      { value: "Email", title: "Email" },
                    ],
                    setup: function (editor) {
                      editor.on("keydown", function (e) {
                        if (
                          editor.getContent({ format: "text" }).length >=
                            1000 &&
                          e.keyCode !== 8 &&
                          e.keyCode !== 46 &&
                          !e.ctrlKey
                        ) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      });
                      editor.ui.registry.addButton("toggleHtml", {
                        text: "Toggle HTML",
                        onAction: function () {
                          // Toggle between visual and code modes
                          if (editor.plugins.code) {
                            editor.execCommand("mceCodeEditor");
                          }
                        },
                      });
                    },
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                  }}
                  value={formData.gameSection.timeUpMessage}
                  onEditorChange={(content, editor) =>
                    setFormData({
                      ...formData,
                      gameSection: {
                        ...formData.gameSection,
                        timeUpMessage: content,
                      },
                    })
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  gap: 1,
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Success Message (HTML):
                </Typography>
                <Editor
                  apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                  rows="5"
                  init={{
                    width: "100%",
                    height: 250,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                      "textcolor colorpicker textpattern",
                      "fontselect fontsize",
                      "code",
                    ],
                    toolbar:
                      "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                    menubar: false,
                    toolbar_mode: "wrap",
                    branding: false,
                    mergetags_list: [
                      { value: "First.Name", title: "First Name" },
                      { value: "Email", title: "Email" },
                    ],
                    setup: function (editor) {
                      editor.on("keydown", function (e) {
                        if (
                          editor.getContent({ format: "text" }).length >=
                            1000 &&
                          e.keyCode !== 8 &&
                          e.keyCode !== 46 &&
                          !e.ctrlKey
                        ) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      });
                      editor.ui.registry.addButton("toggleHtml", {
                        text: "Toggle HTML",
                        onAction: function () {
                          // Toggle between visual and code modes
                          if (editor.plugins.code) {
                            editor.execCommand("mceCodeEditor");
                          }
                        },
                      });
                    },
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                  }}
                  value={formData.gameSection.successMessage}
                  onEditorChange={(content, editor) =>
                    setFormData({
                      ...formData,
                      gameSection: {
                        ...formData.gameSection,
                        successMessage: content,
                      },
                    })
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Click More URL:
                </Typography>
                <InputBase
                  type="text"
                  value={formData.moreUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, moreUrl: e.target.value })
                  }
                  sx={{
                    border: "1px solid #ccc",
                    padding: "3px 10px",
                    color: "#000",
                    flex: 1,
                  }}
                ></InputBase>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Matrix Choice:
                </Typography>
                <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                  <Select
                    value={formData.gameSection.matrixChoice}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        gameSection: {
                          ...formData.gameSection,
                          matrixChoice: e.target.value,
                        },
                      });
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="1">3 x 4</MenuItem>
                    <MenuItem value="2">4 x 4</MenuItem>
                    <MenuItem value="3">4 x 5</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "16px",
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    # {4 + formData.gameSection.matrixChoice * 2} Images: <br />{" "}
                    (500 x 350)
                  </Typography>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload images
                    <input
                      type="file"
                      hidden
                      multiple
                      accept="image/*"
                      onChange={handleImagesUpload}
                    />
                  </Button>
                </Box>
                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                  {formData.images.map((image, index) => (
                    <Grid item xs={3} key={index}>
                      <Box
                        component="img"
                        src={
                          image.preview ||
                          process.env.REACT_APP_API_PUBLIC_URL +
                            "/media/images/" +
                            image
                        }
                        alt={`Image ${index + 1}`}
                        sx={{ width: "100%", height: "auto" }}
                      />
                      <IconButton
                        color="secondary"
                        onClick={() => handleImageRemove(image)}
                        size="small"
                        style={{ marginTop: "5px" }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )}
      {formData.challengeType === "Poirot" && (
        <>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 5, md: 10 }}
            sx={{ mt: 1 }}
          >
            <Grid item xs={12} md={10}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    Poirot Section
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  # Clues (Max 5):
                </Typography>
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <Select
                    value={formData.gameSection.cluesCount}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        gameSection: {
                          ...formData.gameSection,
                          cluesCount: e.target.value,
                        },
                      });
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{
                    fontSize: "16px",
                    color: "#737171",
                    textAlign: "left",
                    minWidth: "200px",
                  }}
                >
                  Display Time (Second):
                </Typography>
                <Select
                  value={formData.gameSection.displayTime}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      gameSection: {
                        ...formData.gameSection,
                        displayTime: e.target.value,
                      },
                    });
                  }}
                  sx={{ width: "100px" }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </Box>
            </Grid>
            {Array(formData.gameSection.cluesCount)
              .fill()
              .map((_, index) => {
                return (
                  <>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component={"label"}
                          sx={{
                            fontSize: "16px",
                            color: "#737171",
                            textAlign: "left",
                            minWidth: "200px",
                          }}
                        >
                          Clue {index + 1} Image:
                        </Typography>
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload file
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, index)}
                          />
                        </Button>
                      </Box>
                      {(formData.images[index]?.preview ||
                        formData.images[index]) && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={
                              formData.images[index]?.preview ||
                              process.env.REACT_APP_API_PUBLIC_URL +
                                "/media/images/" +
                                formData.images[index]
                            }
                            alt={`Image ${index + 1}`}
                            sx={{ width: "70px", height: "auto" }}
                          />
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              handleImageRemove(formData.images[index])
                            }
                            size="small"
                            style={{ marginTop: "5px" }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component={"label"}
                          sx={{
                            fontSize: "16px",
                            color: "#737171",
                            textAlign: "left",
                            minWidth: "200px",
                          }}
                        >
                          Clue {index + 1} Text:
                        </Typography>
                        <InputBase
                          type="text"
                          multiline
                          rows={3}
                          value={formData.gameSection.clues[index]}
                          onChange={(e) => {
                            const newClues = [...formData.gameSection.clues];
                            newClues[index] = e.target.value;
                            setFormData({
                              ...formData,
                              gameSection: {
                                ...formData.gameSection,
                                clues: newClues,
                              },
                            });
                          }}
                          sx={{
                            border: "1px solid #ccc",
                            padding: "3px 10px",
                            color: "#000",
                            flex: 1,
                          }}
                        ></InputBase>
                      </Box>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 5, md: 10 }}
            sx={{ mt: 1 }}
          >
            <Grid item xs={12} md={6}>
              <Stack direction="column" spacing={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "16px",
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    Correct Answer:
                  </Typography>
                  <InputBase
                    type="text"
                    value={formData.gameSection.correctAnswer}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        gameSection: {
                          ...formData.gameSection,
                          correctAnswer: e.target.value,
                        },
                      });
                    }}
                    sx={{
                      border: "1px solid #ccc",
                      padding: "3px 10px",
                      color: "#000",
                      flex: 1,
                    }}
                  ></InputBase>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "16px",
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    Time Up Message (HTML):
                  </Typography>
                  <Editor
                    apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                    rows="5"
                    init={{
                      width: "100%",
                      height: 250,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                        "textcolor colorpicker textpattern",
                        "fontselect fontsize",
                        "code",
                      ],
                      toolbar:
                        "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                      menubar: false,
                      toolbar_mode: "wrap",
                      branding: false,
                      mergetags_list: [
                        { value: "First.Name", title: "First Name" },
                        { value: "Email", title: "Email" },
                      ],
                      setup: function (editor) {
                        editor.on("keydown", function (e) {
                          if (
                            editor.getContent({ format: "text" }).length >=
                              1000 &&
                            e.keyCode !== 8 &&
                            e.keyCode !== 46 &&
                            !e.ctrlKey
                          ) {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        });
                        editor.ui.registry.addButton("toggleHtml", {
                          text: "Toggle HTML",
                          onAction: function () {
                            // Toggle between visual and code modes
                            if (editor.plugins.code) {
                              editor.execCommand("mceCodeEditor");
                            }
                          },
                        });
                      },
                      ai_request: (request, respondWith) =>
                        respondWith.string(() =>
                          Promise.reject("See docs to implement AI Assistant")
                        ),
                    }}
                    value={formData.gameSection.timeUpMessage}
                    onEditorChange={(content, editor) =>
                      setFormData({
                        ...formData,
                        gameSection: {
                          ...formData.gameSection,
                          timeUpMessage: content,
                        },
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "16px",
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    Click More URL:
                  </Typography>
                  <InputBase
                    type="text"
                    value={formData.moreUrl}
                    onChange={(e) =>
                      setFormData({ ...formData, moreUrl: e.target.value })
                    }
                    sx={{
                      border: "1px solid #ccc",
                      padding: "3px 10px",
                      color: "#000",
                      flex: 1,
                    }}
                  ></InputBase>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="column" spacing={2}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    gap: 1,
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "16px",
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    Success Message (HTML):
                  </Typography>
                  <Editor
                    apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                    rows="5"
                    init={{
                      width: "100%",
                      height: 250,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                        "textcolor colorpicker textpattern",
                        "fontselect fontsize",
                        "code",
                      ],
                      toolbar:
                        "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                      menubar: false,
                      toolbar_mode: "wrap",
                      branding: false,
                      mergetags_list: [
                        { value: "First.Name", title: "First Name" },
                        { value: "Email", title: "Email" },
                      ],
                      setup: function (editor) {
                        editor.on("keydown", function (e) {
                          if (
                            editor.getContent({ format: "text" }).length >=
                              1000 &&
                            e.keyCode !== 8 &&
                            e.keyCode !== 46 &&
                            !e.ctrlKey
                          ) {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        });
                        editor.ui.registry.addButton("toggleHtml", {
                          text: "Toggle HTML",
                          onAction: function () {
                            // Toggle between visual and code modes
                            if (editor.plugins.code) {
                              editor.execCommand("mceCodeEditor");
                            }
                          },
                        });
                      },
                      ai_request: (request, respondWith) =>
                        respondWith.string(() =>
                          Promise.reject("See docs to implement AI Assistant")
                        ),
                    }}
                    value={formData.gameSection.successMessage}
                    onEditorChange={(content, editor) =>
                      setFormData({
                        ...formData,
                        gameSection: {
                          ...formData.gameSection,
                          successMessage: content,
                        },
                      })
                    }
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
