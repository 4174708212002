import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
  InputBase,
  Switch,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { Editor } from "@tinymce/tinymce-react";
import { FileCopy } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  createPack,
  getPrograms,
  updatePack,
  getChallenges,
} from "../helper/admin.helper";
import DragAndDropList from "./DragAndDropList";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function CreatePack({ onFinish, isEdit, editData }) {
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    showDescription: true,
    isActive: true,
    image: "",
    siteTagLine: "",
    shortUrl: "",
    challenges: "",
    runSequence: "Set",
    programId: 0,
    programName: "",
    isDedicatedPage: true,
    category: "",
    quizResultMessage1: "",
    quizResultMessage2: "",
    siteTagLine: "",
    bannerAd: "",
    interstitialAd: "",
    tagBarColor: "",
    isiText: "",
    isStikyISI: false,
    plLink: "",
    isiLink: "",
    plOrISIColor: "",
    brandLogo: "",
    indicationInfoText: "",
    indicationColor: "",
    companyInfoText: "",
    legalNoticeLink: "",
    privacyPolicyLink: "",
    companyLogo: "",
    lnAndPpColor: "",
    moreUrl: "",
    optionalContent: "",
  });

  useEffect(() => {
    getPrograms().then((response) => {
      if (response.data) {
        const { data } = response;
        const selectData = data.programs.map((p) => {
          return { id: p.id, name: p.name };
        });

        setPrograms(selectData);
      }
    });

    getChallenges().then((response) => {
      if (response.data) {
        const { data } = response;
        if (data.challenges.length) {
          setChallenges(
            data.challenges.map((challange) => {
              return challange.name;
            })
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log("aaaa", editData);
    if (editData) {
      setFormData({
        name: editData.name,
        description: editData.description,
        showDescription: editData.show_description === "1",
        isActive: editData.is_active === "1",
        image: editData.image ?? "",
        siteTagLine: editData.tag_line ?? "",
        shortUrl: editData.short_url ?? "",
        challenges: editData.challenges ?? "",
        runSequence: editData.run_sequence ?? "Set",
        programId: editData.program_id ?? 0,
        programName: editData.program_name ?? "",
        isDedicatedPage: editData.is_dedicated_page === "1",
        category: editData.category ?? "",
        quizResultMessage1: editData.quiz_result_message_1 ?? "",
        quizResultMessage2: editData.quiz_result_message_2 ?? "",
        bannerAd: editData.banner_ad_id ?? "",
        interstitialAd: editData.interstitial_ad ?? "",
        tagBarColor: editData.tag_bar_color ?? "",
        isiText: editData.isi ?? "",
        isStikyISI: editData.stiky_isi === "1",
        plLink: editData.pi_link ?? "",
        isiLink: editData.isi_link ?? "",
        plOrISIColor: editData.pl_color ?? "",
        brandLogo: editData.brand_logo ?? "",
        indicationInfoText: editData.indication_info ?? "",
        indicationColor: editData.indication_color ?? "",
        companyInfoText: editData.company_info ?? "",
        legalNoticeLink: editData.legal_notice_link ?? "",
        privacyPolicyLink: editData.pp_link ?? "",
        companyLogo: editData.company_logo ?? "",
        lnAndPpColor: editData.ln_pp_color ?? "",
        moreUrl: editData.more_url ?? "",
        optionalContent: editData.optional_content ?? "",
      });
    }
  }, [editData]);

  const handleFileChange = (event, property) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, [property]: file });
    }
  };

  const handleCopy = (type) => {
    const baseUrl = `https://next.pocngo.com`;

    if (type === "long") {
      const url = `${baseUrl}${
        formData.programId && formData.programId !== 0 ? "/program" : ""
      }/pack/${encodeURIComponent(formData.name)}?packid=${
        editData ? editData.id : ""
      }`;
      navigator.clipboard.writeText(url);
      setSnackbarOpen(true);
    } else {
      if (formData.shortUrl && formData.shortUrl !== "") {
        const url = `${baseUrl}${
          formData.programId && formData.programId !== 0 ? "/program" : ""
        }/pack/${encodeURIComponent(formData.shortUrl)}?packid=${
          editData ? editData.id : ""
        }`;
        navigator.clipboard.writeText(url);
        setSnackbarOpen(true);
      } else {
        setOpen(true);
        setResponse({
          status: false,
          message: "This Challenge Pack doesn't have a short URL",
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = () => {
    if (formData.name === "" || formData.description === "") {
      setOpen(true);
      setResponse({
        status: false,
        message: "Please fill out all required fields",
      });

      return;
    }

    if (isEdit) {
      updatePack(formData, editData.id)
        .then((data) => {
          if (data.status) {
            setResponse({
              status: true,
              message: `${formData.name} Challenge pack was updated successfully!`,
            });
            setOpen(true);
            handleCancel();
          } else {
            setResponse({
              status: false,
              message: data.message,
            });
            setOpen(true);
          }
        })
        .catch((errorMessage) => {
          setResponse({
            status: false,
            message: errorMessage,
          });
          setOpen(true);
        });
    } else {
      createPack(formData)
        .then((data) => {
          if (data.status) {
            setResponse({
              status: true,
              message: `${formData.name} Challenge pack is created successfully!`,
            });
            setOpen(true);
            handleCancel();
          } else {
            setResponse({
              status: false,
              message: data.message,
            });
            setOpen(true);
          }
        })
        .catch((errorMessage) => {
          setResponse({
            status: false,
            message: errorMessage,
          });
          setOpen(true);
        });
    }
  };

  const handleCancel = () => {
    onFinish();
    setTimeout(() => {
      setFormData({
        name: "",
        description: "",
        showDescription: true,
        isActive: true,
        image: "",
        siteTagLine: "",
        shortUrl: "",
        challenges: "",
        runSequence: "Set",
        programId: 0,
        programName: "",
        isDedicatedPage: true,
        category: "",
        quizResultMessage1: "",
        quizResultMessage2: "",
        siteTagLine: "",
        bannerAd: "",
        interstitialAd: "",
        tagBarColor: "",
        isiText: "",
        isStikyISI: false,
        plLink: "",
        isiLink: "",
        plOrISIColor: "",
        brandLogo: "",
        indicationInfoText: "",
        indicationColor: "",
        companyInfoText: "",
        legalNoticeLink: "",
        privacyPolicyLink: "",
        companyLogo: "",
        lnAndPpColor: "",
        moreUrl: "",
        optionalContent: "",
      });
    }, 1000);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={response.status ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {response.message}
          </Alert>
        </Snackbar>
        <Typography sx={{ fontWeight: 700, color: "#6f6a6a" }}>
          {isEdit ? "Edit Challenge Pack" : "Create New Challenge Pack"}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={handleSubmit}>
            {isEdit ? "Save Challenge Pack" : "Create Challenge Pack"}
          </Button>
          <Button
            variant="outlined"
            sx={{ bgcolor: "grey[300]" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Challenge Pack Name*:
              </Typography>
              <InputBase
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{ fontSize: "16px", color: "#737171" }}
                >
                  Description*:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{
                      fontSize: "16px",
                      color: "#737171",
                      textAlign: "left",
                      minWidth: "200px",
                    }}
                  >
                    Show Description:
                  </Typography>
                  <Switch
                    checked={formData.showDescription}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        showDescription: e.target.checked,
                      })
                    }
                  />
                </Box>
              </Box>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.description}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, description: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Category:
              </Typography>
              <InputBase
                type="text"
                value={formData.category}
                onChange={(e) =>
                  setFormData({ ...formData, category: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
              {/* <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.category}
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Diagnosis">Diagnosis</MenuItem>
                  <MenuItem value="Treatment">Treatment</MenuItem>
                  <MenuItem value="Industry/Disease State/Specialty Knowledge">
                    Industry/Disease State/Specialty Knowledge
                  </MenuItem>
                  <MenuItem value="Clinical Data">Clinical Data</MenuItem>
                  <MenuItem value="MOA/MOD">MOA/MOD</MenuItem>
                  <MenuItem value="Other General">Other General</MenuItem>
                </Select>
              </FormControl> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Run Sequence:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.runSequence}
                  onChange={(e) =>
                    setFormData({ ...formData, runSequence: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="Set">Set</MenuItem>
                  <MenuItem value="Individual">Individual</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Tag Line
              </Typography>
              <InputBase
                type="text"
                value={formData.siteTagLine}
                onChange={(e) =>
                  setFormData({ ...formData, siteTagLine: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Short URL:
              </Typography>
              <Box display="flex" width="100%">
                <InputBase
                  type="text"
                  value={formData.shortUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, shortUrl: e.target.value })
                  }
                  sx={{
                    border: "1px solid #ccc",
                    padding: "3px 10px",
                    color: "#000",
                    flex: 1,
                  }}
                ></InputBase>
                <Button
                  variant="contained"
                  endIcon={<FileCopy />}
                  size="small"
                  onClick={() => handleCopy("long")}
                >
                  Long
                </Button>
                <Button
                  variant="contained"
                  endIcon={<FileCopy />}
                  size="small"
                  onClick={() => handleCopy("short")}
                >
                  Short
                </Button>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={2000}
                  onClose={handleCloseSnackbar}
                  message="Copied to clipboard!"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Program Name:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.programId}
                  onChange={(e) => {
                    const program = programs.find(
                      (p) => p.id === e.target.value
                    );
                    setFormData({
                      ...formData,
                      programId: e.target.value,
                      programName: program ? program.name : "",
                    });
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  {programs.map((program) => {
                    return (
                      <MenuItem value={program.id}>{program.name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Dedicated Page?:
              </Typography>
              <Switch
                checked={formData.isDedicatedPage}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isDedicatedPage: e.target.checked,
                  })
                }
              />
              <Typography
                component={"label"}
                sx={{
                  fontSize: "14px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                  ml: 2,
                }}
              >
                Use Dedicated Page for Individual Run Sequence
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Banner Ad:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.bannerAd}
                  onChange={(e) =>
                    setFormData({ ...formData, bannerAd: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Interstitial Ad:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.interstitialAd}
                  onChange={(e) =>
                    setFormData({ ...formData, interstitialAd: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Challenge Pack Image: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (550 x 300)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "image")}
                />
              </Button>
              {formData.image.name ||
                (formData.image && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.image.name || formData.image}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setFormData({ ...formData, image: "" })}
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Tag Bar Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.tagBarColor}
                onChange={(e) =>
                  setFormData({ ...formData, tagBarColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Active:
              </Typography>
              <Switch
                checked={formData.isActive}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isActive: e.target.checked,
                  })
                }
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={12}>
          <Stack direction="column" spacing={2}>
            <Typography
              component={"label"}
              sx={{
                fontSize: "16px",
                color: "#737171",
                textAlign: "left",
                minWidth: "200px",
              }}
            >
              Drag & Drop from Available Challenges to Create a Run Sequence.
              Move back to the Available Pool to Deselect
            </Typography>
            <DragAndDropList
              challenges={challenges}
              initValue={editData ? editData.challenges : null}
              onSendSequence={(sequence) =>
                setFormData({ ...formData, challenges: sequence })
              }
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Quiz Results Message ({">=50%"}):
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "floating",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.quizResultMessage1}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, quizResultMessage1: content })
                }
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Quiz Results Message ({"<50%"}):
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "floating",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.quizResultMessage2}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, quizResultMessage2: content })
                }
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontSize: "16px", color: "#737171" }}
                  >
                    ISI Text:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{
                        fontSize: "16px",
                        color: "#737171",
                        textAlign: "left",
                        minWidth: "200px",
                      }}
                    >
                      Sticky ISI:
                    </Typography>
                    <Switch
                      checked={formData.isStikyISI}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          isStikyISI: e.target.checked,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.isiText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, isiText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Optional Content:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.optionalContent}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, optionalContent: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Legal Notice Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.legalNoticeLink}
                onChange={(e) =>
                  setFormData({ ...formData, legalNoticeLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Privacy Policy Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.privacyPolicyLink}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    privacyPolicyLink: e.target.value,
                  })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                LN/PP Link Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.lnAndPpColor}
                onChange={(e) =>
                  setFormData({ ...formData, lnAndPpColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                PI Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.plLink}
                onChange={(e) =>
                  setFormData({ ...formData, plLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                ISI Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.isiLink}
                onChange={(e) =>
                  setFormData({ ...formData, isiLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                PI/ISI Link Text Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.plOrISIColor}
                onChange={(e) =>
                  setFormData({ ...formData, plOrISIColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Brand Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (550 x 300)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "brandLogo")}
                />
              </Button>
              {formData.brandLogo.name ||
                (formData.brandLogo && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.brandLogo.name || formData.brandLogo}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setFormData({ ...formData, brandLogo: "" })
                      }
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                ))}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Indication Info Text:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.on("keydown", function (e) {
                      if (
                        editor.getContent({ format: "text" }).length >= 1000 &&
                        e.keyCode !== 8 &&
                        e.keyCode !== 46 &&
                        !e.ctrlKey
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    });
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.indicationInfoText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, indicationInfoText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Indication Border Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.indicationColor}
                onChange={(e) =>
                  setFormData({ ...formData, indicationColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Company Info Text:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                    "wordcount",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  wordcount_max_chars: 1000,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.on("keyup", function () {
                      if (editor.getContent({ format: "text" }).length > 1000) {
                        editor.setContent(
                          editor.getContent({ format: "text" }).substr(0, 1000)
                        );
                        setOpen(true);
                        setResponse({
                          status: false,
                          message:
                            "You have reached the maximum allowed characters.",
                        });
                      }
                    });
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.companyInfoText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, companyInfoText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Company Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (550 x 300)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "companyLogo")}
                />
              </Button>
              {formData.companyLogo.name ||
                (formData.companyLogo && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.companyLogo.name || formData.companyLogo}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setFormData({ ...formData, companyLogo: "" })
                      }
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Learn More Url:
              </Typography>
              <InputBase
                type="text"
                value={formData.moreUrl}
                onChange={(e) =>
                  setFormData({ ...formData, moreUrl: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
