import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
  Typography,
} from "@mui/material";

function DragAndDropList({ challenges, initValue, onSendSequence }) {
  const [sequence, setSequence] = useState(Array(10).fill(null));
  const [dragIndex, setDragIndex] = useState(null);

  useEffect(() => {
    if (initValue) {
      let array = initValue.split(",");
      array = array.map(item => challenges.includes(item) ? item : "");
      setSequence(array);
    }
  }, [initValue, challenges]);

  const handleDragStart = (e, item, index) => {
    e.dataTransfer.setData("text/plain", item);
    setDragIndex(index);
  };

  const handleDrop = (e, index) => {
    const item = e.dataTransfer.getData("text/plain");
    const newSequence = [...sequence];
    if (index === null) {
      newSequence[dragIndex] = "";
    } else {
      newSequence[index] = item;
    }
    setSequence(newSequence);
    onSendSequence(newSequence);
    setDragIndex(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnd = (e) => {
    handleDrop(e, null);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <List
        component={Paper}
        sx={{
          width: "25%",
          display: "flex",
          flexDirection: "column",
          gap: "7px",
          border: "1px solid #ccc",
          height: "300px",
          overflow: "auto",
        }}
      >
        {challenges.map((challenge, index) => (
          <ListItem
            key={`challenge-${index}`}
            onDragStart={(e) => handleDragStart(e, challenge, null)}
            draggable
            sx={{ bgcolor: "rgb(245 245 245)", cursor: "pointer" }}
          >
            <ListItemText primary={challenge} sx={{ m: 0 }} />
          </ListItem>
        ))}
      </List>

      <List
        sx={{
          width: "25%",
          p: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        component={Box}
      >
        {sequence.slice(0, 5).map((item, index) => (
          <ListItem
            key={`sequence-1-${index}`}
            role={undefined}
            draggable 
            onDragStart={(e) => handleDragStart(e, item, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            onDragEnd={handleDragEnd}
            sx={{ display: "flex", alignContent: "center", p: 0 }}
          >
            <Typography
              sx={{
                width: "50px",
                height: "40px",
                border: "1px solid #ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
              }}
            >
              {index + 1}
            </Typography>
            <ListItemText
              primary={item || ""}
              sx={{
                border: "1px solid #ccc",
                ml: 2,
                minHeightheight: "40px",
                flex: 1,
                p: 1,
              }}
            />
          </ListItem>
        ))}
      </List>
      <List
        sx={{
          width: "25%",
          p: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        component={Box}
      >
        {sequence.slice(5, 10).map((item, index) => (
          <ListItem
          key={`sequence-2-${index}`}
            role={undefined}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDrop(e, index + 5)}
            sx={{ display: "flex", alignContent: "center", p: 0 }}
          >
            <Typography
              sx={{
                width: "50px",
                height: "40px",
                border: "1px solid #ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
              }}
            >
              {index + 6}
            </Typography>
            <ListItemText
              primary={item ? item : ""}
              sx={{
                border: "1px solid #ccc",
                ml: 2,
                height: "40px",
                flex: 1,
                p: 1,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default DragAndDropList;
