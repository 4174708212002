import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
  InputBase,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { getSiteSettings, saveSiteSettings } from "../helper/admin.helper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function EditSiteSettings({ onFinish, isEdit, editData }) {
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });
  const [formData, setFormData] = useState({
    id: 0,
    siteName: "",
    privacyLink: "",
    termsLink: "",
    contactUsLink: "",
    facebookLink: "",
    twitterLink: "",
    linkedinLink: "",
    siteLogo: "",
    footerLogo: "",
    footerText: "",
    copyrightText: "",
    siteTagLine: "",
    tagBarColor: "",
    instagramLink: "",
    learningHubLink: "",
    pocnPlusLink: "",
    mentorProgramLink: "",
    americasTopLink: "",
    beALeaderLink: "",
    siteFavicon: "",
    footerSubText: "",
  });

  useEffect(() => {
    getSiteSettings().then((response) => {
      if (response.data) {
        const { data } = response;
        if (data.settings) {
          setFormData({
            id: data.settings.id,
            siteName: data.settings.site_name ?? "",
            privacyLink: data.settings.privacy_policy_link ?? "",
            termsLink: data.settings.terms_link ?? "",
            contactUsLink: data.settings.contact_us_link ?? "",
            facebookLink: data.settings.facebook_link ?? "",
            twitterLink: data.settings.twitter_link ?? "",
            linkedinLink: data.settings.linkedin_link ?? "",
            siteLogo: data.settings.site_logo ?? "",
            footerLogo: data.settings.footer_logo ?? "",
            footerText: data.settings.footer_text ?? "",
            copyrightText: data.settings.copyright_text ?? "",
            siteTagLine: data.settings.site_tag_line ?? "",
            tagBarColor: data.settings.tag_bar_color ?? "",
            instagramLink: data.settings.instagram_link ?? "",
            learningHubLink: data.settings.learning_hub_link ?? "",
            pocnPlusLink: data.settings.pocn_plus_link ?? "",
            mentorProgramLink: data.settings.mentor_program_link ?? "",
            americasTopLink: data.settings.americas_top_link ?? "",
            beALeaderLink: data.settings.be_a_leader_link ?? "",
            siteFavicon: data.settings.site_favicon ?? "",
            footerSubText: data.settings.footer_sub_text ?? "",
          });
        }
      }
    });
  }, []);

  const handleSubmit = () => {
    saveSiteSettings(formData, formData.id)
      .then((data) => {
        setFormData({ ...formData, id: data.settings.id });
        setResponse({
          status: true,
          message: `Site Settings are updated successfully!`,
        });
        setOpen(true);
        handleCancel();
      })
      .catch((errorMessage) => {
        setResponse({
          status: false,
          message: errorMessage,
        });
        setOpen(true);
      });
  };

  const handleCancel = () => {};

  const handleFileChange = (event, property) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, [property]: file });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={response.status ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {response.message}
          </Alert>
        </Snackbar>
        <Typography sx={{ fontWeight: 700, color: "#6f6a6a" }}>
          Site Settings
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{ bgcolor: "grey[300]" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Site Name:
              </Typography>
              <InputBase
                type="text"
                value={formData.siteName}
                onChange={(e) =>
                  setFormData({ ...formData, siteName: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Privacy Policy Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.privacyLink}
                onChange={(e) =>
                  setFormData({ ...formData, privacyLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Terms of Use Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.termsLink}
                onChange={(e) =>
                  setFormData({ ...formData, termsLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Contact Us Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.contactUsLink}
                onChange={(e) =>
                  setFormData({ ...formData, contactUsLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Facebook Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.facebookLink}
                onChange={(e) =>
                  setFormData({ ...formData, facebookLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Twitter Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.twitterLink}
                onChange={(e) =>
                  setFormData({ ...formData, twitterLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Linkedin Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.linkedinLink}
                onChange={(e) =>
                  setFormData({ ...formData, linkedinLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Site Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (350 x 150)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "siteLogo")}
                />
              </Button>
              {(formData.siteLogo.name || formData.siteLogo) && (
                <>
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      color: "green",
                      fontSize: "16px",
                    }}
                  >
                    {formData.siteLogo.name || formData.siteLogo}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    onClick={() => setFormData({ ...formData, siteLogo: "" })}
                  >
                    <DeleteIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Footer Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (350 x 150)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "footerLogo")}
                />
              </Button>
              {formData.footerLogo.name ||
                (formData.footerLogo && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.footerLogo.name || formData.footerLogo}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setFormData({ ...formData, footerLogo: "" })
                      }
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                ))}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Site Tag Line:
              </Typography>
              <InputBase
                type="text"
                value={formData.siteTagLine}
                onChange={(e) =>
                  setFormData({ ...formData, siteTagLine: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Tag Bar Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.tagBarColor}
                onChange={(e) =>
                  setFormData({ ...formData, tagBarColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Instagram Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.instagramLink}
                onChange={(e) =>
                  setFormData({ ...formData, instagramLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Learning Hub Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.learningHubLink}
                onChange={(e) =>
                  setFormData({ ...formData, learningHubLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                POCN+ Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.pocnPlusLink}
                onChange={(e) =>
                  setFormData({ ...formData, pocnPlusLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Mentor Program Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.mentorProgramLink}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    mentorProgramLink: e.target.value,
                  })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Americas Top Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.americasTopLink}
                onChange={(e) =>
                  setFormData({ ...formData, americasTopLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Be a Leader:
              </Typography>
              <InputBase
                type="text"
                value={formData.beALeaderLink}
                onChange={(e) =>
                  setFormData({ ...formData, beALeaderLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Site Favicon: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (100 x 100)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "siteFavicon")}
                />
              </Button>
              {formData.siteFavicon.name ||
                (formData.siteFavicon && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.siteFavicon.name || formData.siteFavicon}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setFormData({ ...formData, siteFavicon: "" })
                      }
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                ))}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Footer Text:
              </Typography>
              <InputBase
                multiline
                rows={3}
                type="text"
                value={formData.footerText}
                onChange={(e) =>
                  setFormData({ ...formData, footerText: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Footer Sub-Text:
              </Typography>
              <InputBase
                multiline
                rows={3}
                type="text"
                value={formData.footerSubText}
                onChange={(e) =>
                  setFormData({ ...formData, footerSubText: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Copyright Text:
              </Typography>
              <InputBase
                type="text"
                value={formData.copyrightText}
                onChange={(e) =>
                  setFormData({ ...formData, copyrightText: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
