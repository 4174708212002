import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
  InputBase,
  Switch,
  FormControl,
  Select,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { FileCopy } from "@mui/icons-material";
import { Editor } from "@tinymce/tinymce-react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import { createProgram, editProgram } from "../helper/admin.helper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function CreateProgram({ onFinish, isEdit, editData }) {
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    showDescription: false,
    isActive: true,
    image: "",
    siteTagLine: "",
    shortUrl: "",
    clientName: "",
    brandName: "",
    therapeuticArea: "",
    startDate: dayjs(),
    endDate: dayjs(),
    bannerAd: 0,
    interstitialAd: 0,
    tagBarColor: "",
    isiText: "",
    isStikyISI: false,
    plLink: "",
    isiLink: "",
    plOrISIColor: "",
    brandLogo: "",
    indicationInfoText: "",
    indicationColor: "",
    companyInfoText: "",
    legalNoticeLink: "",
    privacyPolicyLink: "",
    companyLogo: "",
    lnAndPpColor: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = (type) => {
    const baseUrl = `https://next.pocngo.com/program/`;

    if (type === "long") {
      const url = `${baseUrl}${encodeURIComponent(formData.name)}?programid=${
        editData ? editData.id : ""
      }`;
      navigator.clipboard.writeText(url);
      setSnackbarOpen(true);
    } else {
      if (formData.shortUrl && formData.shortUrl !== "") {
        const url = `${baseUrl}${encodeURIComponent(
          formData.shortUrl
        )}?programid=${editData ? editData.id : ""}`;
        navigator.clipboard.writeText(url);
        setSnackbarOpen(true);
      } else {
        setOpen(true);
        setResponse({
          status: false,
          message: "This program doesn't have a short URL",
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (editData) {
      setFormData({
        name: editData.name,
        description: editData.description,
        showDescription: editData.show_description === "1",
        isActive: editData.is_active === "1" || editData.is_active === 1,
        image: editData.image ?? "",
        siteTagLine: editData.tag_line ?? "",
        shortUrl: editData.short_url ?? "",
        clientName: editData.client_name ?? "",
        brandName: editData.brand_name ?? "",
        therapeuticArea: editData.therapeutic_area ?? "",
        startDate: dayjs(editData.start_date ?? new Date()),
        endDate: dayjs(editData.end_date ?? new Date()),
        bannerAd: editData.banner_ad_id ?? 0,
        interstitialAd: editData.interstitial_ad ?? 0,
        tagBarColor: editData.tag_bar_color ?? "",
        isiText: editData.isi ?? "",
        isStikyISI: editData.stiky_isi === "1",
        plLink: editData.pi_link ?? "",
        isiLink: editData.isi_link ?? "",
        plOrISIColor: editData.pl_color ?? "",
        brandLogo: editData.brand_logo ?? "",
        indicationInfoText: editData.indication_info ?? "",
        indicationColor: editData.indication_color ?? "",
        companyInfoText: editData.company_info ?? "",
        legalNoticeLink: editData.legal_notice_link ?? "",
        privacyPolicyLink: editData.pp_link ?? "",
        companyLogo: editData.company_logo ?? "",
        lnAndPpColor: editData.ln_pp_color ?? "",
      });
    }
  }, [editData]);

  const handleFileChange = (event, property) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, [property]: file });
    }
  };

  const handleSubmit = () => {
    if (formData.name === "" || formData.description === "") {
      setOpen(true);
      setResponse({
        status: false,
        message: "Please fill out all required fields",
      });

      return;
    }
    if (isEdit) {
      editProgram(formData, editData.id)
        .then((data) => {
          if (data.status) {
            setResponse({
              status: true,
              message: `${formData.name} Program is updated successfully!`,
            });
            setOpen(true);
            handleCancel();
          } else {
            setResponse({
              status: false,
              message: data.message,
            });
            setOpen(true);
          }
        })
        .catch((errorMessage) => {
          setResponse({
            status: false,
            message: errorMessage,
          });
          setOpen(true);
        });
    } else {
      createProgram(formData)
        .then((data) => {
          if (data.status) {
            setResponse({
              status: true,
              message: `${formData.name} Program is created successfully!`,
            });
            setOpen(true);
            handleCancel();
          } else {
            setResponse({
              status: false,
              message: data.message,
            });
            setOpen(true);
          }
        })
        .catch((errorMessage) => {
          setResponse({
            status: false,
            message: errorMessage,
          });
          setOpen(true);
        });
    }
  };

  const handleCancel = () => {
    setFormData({
      name: "",
      description: "",
      showDescription: false,
      image: "",
      siteTagLine: "",
      shortUrl: "",
      clientName: "",
      brandName: "",
      therapeuticArea: "",
      startDate: dayjs(),
      endDate: dayjs(),
      bannerAd: 0,
      interstitialAd: 0,
      tagBarColor: "",
      isiText: "",
      isStikyISI: false,
      plLink: "",
      isiLink: "",
      plOrISIColor: "",
      brandLogo: "",
      indicationInfoText: "",
      indicationColor: "",
      companyInfoText: "",
      legalNoticeLink: "",
      privacyPolicyLink: "",
      companyLogo: "",
      lnAndPpColor: "",
    });

    onFinish();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={response.status ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {response.message}
          </Alert>
        </Snackbar>
        <Typography sx={{ fontWeight: 700, color: "#6f6a6a" }}>
          {isEdit ? "Edit Program" : "Create New Program"}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={handleSubmit}>
            {isEdit ? "Save Program" : "Create Program"}
          </Button>
          <Button
            variant="outlined"
            sx={{ bgcolor: "grey[300]" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 3, sm: 5, md: 10 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Program Name*:
              </Typography>
              <InputBase
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{ fontSize: "16px", color: "#737171" }}
              >
                Description*:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                    "directionality",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.description}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, description: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Show Description:
              </Typography>
              <Switch
                checked={formData.showDescription}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    showDescription: e.target.checked,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Active:
              </Typography>
              <Switch
                checked={formData.isActive}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    isActive: e.target.checked,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "150px",
                }}
              >
                Program Image: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (860 x 350)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "image")}
                />
              </Button>
              {(formData.image.name || formData.image) && (
                <>
                  <Typography
                    sx={{
                      marginLeft: "20px",
                      color: "green",
                      fontSize: "16px",
                    }}
                  >
                    {formData.image.name || formData.image}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    onClick={() => setFormData({ ...formData, image: "" })}
                  >
                    <DeleteIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Program Tag Line
              </Typography>
              <InputBase
                type="text"
                value={formData.siteTagLine}
                onChange={(e) =>
                  setFormData({ ...formData, siteTagLine: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Program Short URL:
              </Typography>
              <Box display="flex" width="100%">
                <InputBase
                  type="text"
                  value={formData.shortUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, shortUrl: e.target.value })
                  }
                  sx={{
                    border: "1px solid #ccc",
                    padding: "3px 10px",
                    color: "#000",
                    flex: 1,
                  }}
                ></InputBase>
                <Button
                  variant="contained"
                  endIcon={<FileCopy />}
                  size="small"
                  onClick={() => handleCopy("long")}
                >
                  Long
                </Button>
                <Button
                  variant="contained"
                  endIcon={<FileCopy />}
                  size="small"
                  onClick={() => handleCopy("short")}
                >
                  Short
                </Button>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={2000}
                  onClose={handleCloseSnackbar}
                  message="Copied to clipboard!"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Client Name:
              </Typography>
              <InputBase
                type="text"
                value={formData.clientName}
                onChange={(e) =>
                  setFormData({ ...formData, clientName: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Brand Name:
              </Typography>
              <InputBase
                type="text"
                value={formData.brandName}
                onChange={(e) =>
                  setFormData({ ...formData, brandName: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Therapeutic Area:
              </Typography>
              <InputBase
                type="text"
                value={formData.therapeuticArea}
                onChange={(e) =>
                  setFormData({ ...formData, therapeuticArea: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Start Date:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={formData.startDate}
                  onChange={(newValue) =>
                    setFormData({ ...formData, startDate: newValue })
                  }
                  sx={{
                    flex: 1,
                    "& .MuiInputBase-input": {
                      p: "14.5px 14px",
                      height: "1em",
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                End Date:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={formData.endDate}
                  onChange={(newValue) =>
                    setFormData({ ...formData, endDate: newValue })
                  }
                  sx={{
                    flex: 1,
                    "& .MuiInputBase-input": {
                      p: "14.5px 14px",
                      height: "1em",
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Banner Ad:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.bannerAd}
                  onChange={(e) =>
                    setFormData({ ...formData, bannerAd: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Interstitial Ad:
              </Typography>
              <FormControl sx={{ minWidth: 120, flex: 1 }} size="small">
                <Select
                  value={formData.interstitialAd}
                  onChange={(e) =>
                    setFormData({ ...formData, interstitialAd: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ textAlign: "left" }}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem> */}
                  {/* <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Tag Bar Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.tagBarColor}
                onChange={(e) =>
                  setFormData({ ...formData, tagBarColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontSize: "16px", color: "#737171" }}
                  >
                    ISI Text:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{
                        fontSize: "16px",
                        color: "#737171",
                        textAlign: "left",
                        minWidth: "200px",
                      }}
                    >
                      Sticky ISI:
                    </Typography>
                    <Switch
                      checked={formData.isStikyISI}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          isStikyISI: e.target.checked,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.isiText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, isiText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Legal Notice Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.legalNoticeLink}
                onChange={(e) =>
                  setFormData({ ...formData, legalNoticeLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Privacy Policy Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.privacyPolicyLink}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    privacyPolicyLink: e.target.value,
                  })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                LN/PP Link Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.lnAndPpColor}
                onChange={(e) =>
                  setFormData({ ...formData, lnAndPpColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                PI Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.plLink}
                onChange={(e) =>
                  setFormData({ ...formData, plLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                ISI Link:
              </Typography>
              <InputBase
                type="text"
                value={formData.isiLink}
                onChange={(e) =>
                  setFormData({ ...formData, isiLink: e.target.value })
                }
                sx={{
                  border: "1px solid #ccc",
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                PI/ISI Link Text Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.plOrISIColor}
                onChange={(e) =>
                  setFormData({ ...formData, plOrISIColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Brand Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (550 x 300)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "brandLogo")}
                />
              </Button>
              {formData.brandLogo.name ||
                (formData.brandLogo && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.brandLogo.name || formData.brandLogo}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setFormData({ ...formData, brandLogo: "" })
                      }
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                ))}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Indication Info Text:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.on("keydown", function (e) {
                      if (
                        editor.getContent({ format: "text" }).length >= 1000 &&
                        e.keyCode !== 8 &&
                        e.keyCode !== 46 &&
                        !e.ctrlKey
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    });
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.indicationInfoText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, indicationInfoText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Indication Border Color:
              </Typography>
              <InputBase
                type="color"
                value={formData.indicationColor}
                onChange={(e) =>
                  setFormData({ ...formData, indicationColor: e.target.value })
                }
                sx={{
                  padding: "3px 10px",
                  color: "#000",
                  flex: 1,
                  minWidth: "50px", // Ensuring the color picker is not too small
                }}
              ></InputBase>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Company Info Text:
              </Typography>
              <Editor
                apiKey="u5et8aj5bqyatwlyvojug307f1fcys8m10h7ur0ydf3dnvdr"
                rows="5"
                init={{
                  width: "100%",
                  height: 200,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                    "textcolor colorpicker textpattern",
                    "fontselect fontsize",
                    "code",
                    "wordcount",
                  ],
                  toolbar:
                    "formatselect | bold italic underline strikethrough backcolor | forecolor | fontselect fontsize | styles | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            toggleHtml",
                  menubar: false,
                  toolbar_mode: "wrap",
                  branding: false,
                  wordcount_max_chars: 1000,
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  setup: function (editor) {
                    editor.on("keyup", function () {
                      if (editor.getContent({ format: "text" }).length > 1000) {
                        editor.setContent(
                          editor.getContent({ format: "text" }).substr(0, 1000)
                        );
                        setOpen(true);
                        setResponse({
                          status: false,
                          message:
                            "You have reached the maximum allowed characters.",
                        });
                      }
                    });
                    editor.ui.registry.addButton("toggleHtml", {
                      text: "Toggle HTML",
                      onAction: function () {
                        // Toggle between visual and code modes
                        if (editor.plugins.code) {
                          editor.execCommand("mceCodeEditor");
                        }
                      },
                    });
                  },
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={formData.companyInfoText}
                onEditorChange={(content, editor) =>
                  setFormData({ ...formData, companyInfoText: content })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                component={"label"}
                sx={{
                  fontSize: "16px",
                  color: "#737171",
                  textAlign: "left",
                  minWidth: "200px",
                }}
              >
                Company Logo: <br />
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (550 x 300)
                </Typography>
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "companyLogo")}
                />
              </Button>
              {formData.companyLogo.name ||
                (formData.companyLogo && (
                  <>
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        color: "green",
                        fontSize: "16px",
                      }}
                    >
                      {formData.companyLogo.name || formData.companyLogo}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setFormData({ ...formData, companyLogo: "" })
                      }
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </>
                ))}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
