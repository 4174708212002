import axios from "axios";

const APP_URL = process.env.REACT_APP_API_URL;

export const login = (formData, onUploadProgress) => {
  return axios.post(`${APP_URL}/admin/login`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

export const createProgram = (data) => {
  return new Promise((resolve, reject) => {
    // Add 'return' here
    const adminToken = localStorage.getItem("admin_token");
    let formData = new FormData();

    if (data.image && data.image !== "") {
      formData.append(`image`, data.image);
    }

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    axios
      .post(`${APP_URL}/admin/create-program`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.open("/", "_self");
        } else {
          const errorMessage =
            err.response && typeof err.response.data.message === "object"
              ? JSON.stringify(err.response.data.message)
              : err.response.data.message || "Unknown error";

          reject(errorMessage);
        }
      });
  });
};

export const createPack = (data) => {
  return new Promise((resolve, reject) => {
    // Add 'return' here
    const adminToken = localStorage.getItem("admin_token");
    let formData = new FormData();

    if (data.image && data.image !== "") {
      formData.append(`image`, data.image);
    }

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    axios
      .post(`${APP_URL}/admin/create-challenge-pack`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.open("/", "_self");
        } else {
          const errorMessage =
            err.response && typeof err.response.data.message === "object"
              ? JSON.stringify(err.response.data.message)
              : err.response.data.message || "Unknown error";

          reject(errorMessage);
        }
      });
  });
};

export const createChallenge = (data) => {
  return new Promise((resolve, reject) => {
    // Add 'return' here
    const adminToken = localStorage.getItem("admin_token");
    let formData = new FormData();

    if (data.challengeBGImage && data.challengeBGImage !== "") {
      formData.append(`challengeBGImage`, data.challengeBGImage);
    }

    if (data.successMessageImage && data.successMessageImage !== "") {
      formData.append(`successMessageImage`, data.successMessageImage);
    }

    if (data.thumbnailImage && data.thumbnailImage !== "") {
      formData.append(`thumbnailImage`, data.thumbnailImage);
    }

    if (data.challangeImage && data.challangeImage !== "") {
      formData.append(`challangeImage`, data.challangeImage);
    }

    if (data.incorrectMessageImage && data.incorrectMessageImage !== "") {
      formData.append(`incorrectMessageImage`, data.incorrectMessageImage);
    }

    data.images.forEach((image, index) => {
      if (image.file) {
        formData.append(`images[]`, image.file);
      }
    });

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    axios
      .post(`${APP_URL}/admin/create-challenge`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.open("/", "_self");
        } else {
          const errorMessage =
            err.response && typeof err.response.data.message === "object"
              ? JSON.stringify(err.response.data.message)
              : err.response.data.message || "Unknown error";

          reject(errorMessage);
        }
      });
  });
};

export const updateChallenge = (data, id) => {
  return new Promise((resolve, reject) => {
    // Add 'return' here
    const adminToken = localStorage.getItem("admin_token");
    let formData = new FormData();

    if (data.challengeBGImage && data.challengeBGImage !== "") {
      formData.append(`challengeBGImage`, data.challengeBGImage);
    }

    if (data.successMessageImage && data.successMessageImage !== "") {
      formData.append(`successMessageImage`, data.successMessageImage);
    }

    if (data.thumbnailImage && data.thumbnailImage !== "") {
      formData.append(`thumbnailImage`, data.thumbnailImage);
    }

    if (data.challangeImage && data.challangeImage !== "") {
      formData.append(`challangeImage`, data.challangeImage);
    }

    if (data.incorrectMessageImage && data.incorrectMessageImage !== "") {
      formData.append(`incorrectMessageImage`, data.incorrectMessageImage);
    }

    let hasFile = false;
    data.images.forEach((image, index) => {
      if (image.file) {
        formData.append(`images[]`, image.file);
        hasFile = true;
      }
    });

    Object.keys(data).forEach((key) =>
      formData.append(
        key,
        key === "images" && !hasFile ? JSON.stringify(data[key]) : data[key]
      )
    );

    axios
      .post(`${APP_URL}/admin/update-challenge/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.open("/", "_self");
        } else {
          const errorMessage =
            err.response && typeof err.response.data.message === "object"
              ? JSON.stringify(err.response.data.message)
              : err.response.data.message || "Unknown error";

          reject(errorMessage);
        }
      });
  });
};

export const editProgram = (data, id) => {
  return new Promise((resolve, reject) => {
    const adminToken = localStorage.getItem("admin_token");
    let formData = new FormData();

    if (data.image && data.image !== "") {
      formData.append(`image`, data.image);
    }

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    axios
      .post(`${APP_URL}/admin/update-program/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.open("/", "_self");
        } else {
          const errorMessage =
            err.response && typeof err.response.data.message === "object"
              ? JSON.stringify(err.response.data.message)
              : err.response.data.message || "Unknown error";

          reject(errorMessage);
        }
      });
  });
};

export const updatePack = (data, id) => {
  return new Promise((resolve, reject) => {
    const adminToken = localStorage.getItem("admin_token");
    let formData = new FormData();

    if (data.image && data.image !== "") {
      formData.append(`image`, data.image);
    }

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    axios
      .post(`${APP_URL}/admin/update-challenge-pack/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.open("/", "_self");
        } else {
          const errorMessage =
            err.response && typeof err.response.data.message === "object"
              ? JSON.stringify(err.response.data.message)
              : err.response.data.message || "Unknown error";

          reject(errorMessage);
        }
      });
  });
};

export async function deleteProgram(id) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.delete(
      `${APP_URL}/admin/delete-program/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getPrograms() {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(`${APP_URL}/admin/get-programs`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getProgramById(id) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(`${APP_URL}/admin/get-program/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getChallengePacks() {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(`${APP_URL}/admin/get-challenge-packs`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getChallengePackById(id) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(
      `${APP_URL}/admin/get-challenge-packs/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getChallengePacksByProgramId(programId) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(
      `${APP_URL}/admin/get-challenge-packs-by-programId/${programId}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getChallengePacksByFree() {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(
      `${APP_URL}/admin/get-challenge-free-packs`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function checkProgramDeletableById(id) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(`${APP_URL}/admin/check-program/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function deleteChallengePack(id) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.delete(
      `${APP_URL}/admin/delete-challenge-pack/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getChallenges() {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(`${APP_URL}/admin/get-challenges`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getChallengesByPackId(packId) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(
      `${APP_URL}/admin/get-challenges-by-packId/${packId}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function getChallengeById(id) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(`${APP_URL}/admin/get-challenge/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export async function deleteChallenge(id) {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.delete(
      `${APP_URL}/admin/delete-challenge/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}

export const saveSiteSettings = (data, id) => {
  return new Promise((resolve, reject) => {
    const adminToken = localStorage.getItem("admin_token");
    let formData = new FormData();

    if (data.siteLogo && data.siteLogo !== "") {
      formData.append(`siteLogo`, data.siteLogo);
    }

    if (data.footerLogo && data.footerLogo !== "") {
      formData.append(`footerLogo`, data.footerLogo);
    }

    if (data.siteFavicon && data.siteFavicon !== "") {
      formData.append(`siteFavicon`, data.siteFavicon);
    }

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    axios
      .post(`${APP_URL}/admin/save-site-settings/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${adminToken}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.open("/", "_self");
        } else {
          const errorMessage =
            err.response && typeof err.response.data.message === "object"
              ? JSON.stringify(err.response.data.message)
              : err.response.data.message || "Unknown error";

          reject(errorMessage);
        }
      });
  });
};

export async function getSiteSettings() {
  const adminToken = localStorage.getItem("admin_token");
  try {
    const response = await axios.get(`${APP_URL}/admin/get-site-settings`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${adminToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
    }
  }
}
