import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import dayjs from "dayjs";
import CustomTable from "../components/CustomTable";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CreatePack from "../components/CreatePack";
import ConfimationDialog from "../components/ConfimationDialog";
import {
  getChallengePacks,
  getChallengePackById,
  getChallengesByPackId,
  deleteChallengePack,
} from "../helper/admin.helper";
import { logout } from "../store/admin";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../components/StyleComponents";

function createData(
  id,
  name,
  packId,
  isActive,
  category,
  programName,
  shortUrl,
  challenges,
  createdDate,
  updatedDate
) {
  return {
    id,
    packId,
    name,
    isActive: isActive === "1" ? "Active" : "Inactive",
    category,
    programName,
    shortUrl,
    challenges,
    createdDate,
    updatedDate,
  };
}

const columns = [
  { id: "packId", align: "left" },
  { id: "name", align: "left" },
  { id: "isActive", align: "left" },
  { id: "category", align: "left" },
  { id: "programName", align: "left" },
  { id: "shortUrl", align: "left" },
  { id: "challenges", align: "left" },
  { id: "createdDate", align: "left" },
  { id: "updatedDate", align: "right" },
];

const headCells = [
  {
    id: "packId",
    numeric: false,
    disablePadding: false,
    label: "Challenge Pack Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Challenge Pack Name",
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "programName",
    numeric: false,
    disablePadding: false,
    label: "Program Name",
  },
  {
    id: "shortUrl",
    numeric: false,
    disablePadding: false,
    label: "Short URL",
  },
  {
    id: "challenges",
    numeric: false,
    disablePadding: false,
    label: "#Challenges",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "updatedDate",
    numeric: true,
    disablePadding: false,
    label: "Last Updated Date",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

export default function ChallengePacks() {
  const [isCreate, setIsCreate] = useState(false);
  const [challengePacks, setChallengePacks] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!admin) {
      dispatch(logout());
      return;
    }

    refreshPacks();
  }, [admin]);

  useEffect(() => {
    const filtered = challengePacks.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.programId && item.programId == searchTerm) ||
        (item.programName && item.programName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.category && item.category.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredData(filtered);
  }, [searchTerm, challengePacks]);

  const refreshPacks = () => {
    getChallengePacks().then((response) => {
      if (response.data) {
        const { data } = response;
        const tableData = data.packs.map((p) =>
          createData(
            p.id,
            p.name,
            p.id,
            p.is_active,
            p.category,
            p.program_name,
            p.short_url,
            p.challenges,
            formatDate(p.created_at),
            formatDate(p.updated_at)
          )
        );

        setIsCreate(false);
        setChallengePacks(tableData);
      }
    });
  };

  const handleEdit = (id) => {
    setIsCreate(true);
    setIsEdit(true);
    getChallengePackById(id).then((response) => {
      if (response.data) {
        const { data } = response;
        setEditData(data.pack);
      }
    });
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    getChallengesByPackId(id).then((response) => {
      if (response.data) {
        const { data } = response;
        if (data.challenges.length) {
          setOpen(true);
          setResponse({
            status: false,
            message: `You can not delete the Challenge Pack because it's including challenges.`,
          });
        } else {
          setOpenDialog(true);
        }
      }
    });
    
  };

  const handleConfirmDelete = () => {
    deleteChallengePack(deleteId).then(({ data }) => {
      setResponse({
        status: true,
        message: `The Challenge pack was deleted successfully!`,
      });
      setOpen(true);
      setOpenDialog(false);
      refreshPacks();
    });
  };

  const handleAddPack = () => {
    setIsCreate(true);
    setIsEdit(false);
    setEditData(undefined);
  };

  const formatDate = (date) => {
    if (!date || date === "" || date === "0000-00-00 00:00:00") {
      return "";
    }

    return dayjs(date).format("MMMM D, YYYY, h:mm A");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Container maxWidth="unset" sx={{ mt: 4, mb: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={response.status ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {response.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            {isCreate ? (
              <CreatePack
                onFinish={refreshPacks}
                isEdit={isEdit}
                editData={editData}
              />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Manage Challenge Packs</Typography>
                  <Box display="flex" gap={2}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </Search>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddPack}
                    >
                      Add Challenge Pack
                    </Button>
                  </Box>
                </Box>
                <CustomTable
                  headCells={headCells}
                  data={filteredData}
                  columns={columns}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      {openDialog && (
        <ConfimationDialog
          title="Are you sure to delete this challenge pack?"
          description="Please click 'Confirm' to delete, or 'Cancel' to abort the action."
          onCancel={() => setOpenDialog(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Container>
  );
}
