import React from "react";
import { FormControl, Stack, Typography, InputBase } from "@mui/material";
import { useField } from "formik";

export default function LoginInput ({ label, ...props }) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const inputStyle = {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    p: "7px 14px",
    borderColor: errorText ? "#f44336" : "rgba(0, 0, 0, 0.23)", // Red when there's an error, default otherwise
  };

  return (
    <FormControl fullWidth error={!!errorText}>
      <Stack
        rowGap={1}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="baseline"
      >
        <Typography component="label">{label}</Typography>
        <InputBase {...field} {...props} sx={inputStyle} />
        <Typography
          component="span"
          sx={{ fontSize: "14px", color: "#f44336" }}
        >
          {errorText}
        </Typography>
      </Stack>
    </FormControl>
  );
};
