import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Grid,
  Paper,
} from "@mui/material";
import EditSiteSettings from "../components/EditSiteSettings";

import { logout } from "../store/admin";

export default function SiteSettings() {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!admin) {
      dispatch(logout());
      return;
    }
  }, [admin]);

  return (
    <Container maxWidth="unset" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <EditSiteSettings />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
