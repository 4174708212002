import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TerminalIcon from "@mui/icons-material/Terminal";

export default function MainListItems() {
  const [open, setOpen] = useState({
    dashboard: true,
    program: false,
    ads: false,
    users: false,
    site: false,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClickList = (item) => {
    const newState = {
      dashboard: false,
      program: false,
      ads: false,
      users: false,
      site: false,
    };

    newState[item] = !open[item];

    setOpen(newState);

    if (item !== "program") {
      navigate(`/${item === "site" ? "site-configuration" : item}`);
    }
  };

  return (
    <>
      <ListItemButton
        onClick={() => handleClickList("dashboard")}
        sx={{
          bgcolor: open.dashboard ? "#00B0F0" : "transparent",
          color: open.dashboard ? "#fff" : "rgba(0, 0, 0, 0.77)",
          "&:hover": {
            bgcolor: "#71d2f5",
            color: "#fff",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <BoltOutlinedIcon sx={{ color: open.dashboard ? "#fff" : "rgba(0, 0, 0, 0.77)", }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleClickList("program")}
        sx={{
          bgcolor: open.program ? "#00B0F0" : "transparent",
          color: open.program ? "#fff" : "rgba(0, 0, 0, 0.77)",
          "&:hover": {
            bgcolor: "#71d2f5",
            color: "#fff",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <TerminalIcon sx={{ color: open.program ? "#fff" : "rgba(0, 0, 0, 0.77)", }} />
        </ListItemIcon>
        <ListItemText primary="Manage Programs" />
        {open.program ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
      </ListItemButton>
      <Collapse in={open.program} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/programs")}>
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <ArrowRightIcon sx={{ color: currentPath === "/programs" ? "#00B0F0" : "inherit" }} />
            </ListItemIcon>
            <ListItemText primary="Programs" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/challenge-packs")}>
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <ArrowRightIcon sx={{ color: currentPath === "/challenge-packs" ? "#00B0F0" : "inherit" }} />
            </ListItemIcon>
            <ListItemText primary="Challenge Packs" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/challenges")}>
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <ArrowRightIcon sx={{ color: currentPath === "/challenges" ? "#00B0F0" : "inherit" }} />
            </ListItemIcon>
            <ListItemText primary="Challenges" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton
        onClick={() => handleClickList("ads")}
        sx={{
          bgcolor: open.ads ? "#00B0F0" : "transparent",
          color: open.ads ? "#fff" : "rgba(0, 0, 0, 0.77)",
          "&:hover": {
            bgcolor: "#71d2f5",
            color: "#fff",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <VideocamOutlinedIcon sx={{ color: open.ads ? "#fff" : "rgba(0, 0, 0, 0.77)", }} />
        </ListItemIcon>
        <ListItemText primary="Manage Ads" />
        {open.ads ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
      </ListItemButton>
      <ListItemButton
        onClick={() => handleClickList("users")}
        sx={{
          bgcolor: open.users ? "#00B0F0" : "transparent",
          color: open.users ? "#fff" : "rgba(0, 0, 0, 0.77)",
          "&:hover": {
            bgcolor: "#71d2f5",
            color: "#fff",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <ManageAccountsOutlinedIcon sx={{ color: open.users ? "#fff" : "rgba(0, 0, 0, 0.77)", }} />
        </ListItemIcon>
        <ListItemText primary="Manage Users" />
        {open.users ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
      </ListItemButton>
      <ListItemButton
        onClick={() => handleClickList("site")}
        sx={{
          bgcolor: open.site ? "#00B0F0" : "transparent",
          color: open.site ? "#fff" : "rgba(0, 0, 0, 0.77)",
          "&:hover": {
            bgcolor: "#71d2f5",
            color: "#fff",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <SettingsOutlinedIcon sx={{ color: open.site ? "#fff" : "rgba(0, 0, 0, 0.77)", }} />
        </ListItemIcon>
        <ListItemText primary="Site Configuration" />
        {open.site ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
      </ListItemButton>
    </>
  );
};
