const REPLACE_ADMIN = "REPLACE_ADMIN";
const UPDATE_ADMIN = "UPDATE_ADMIN";
const LOGOUT_ADMIN = "LOGOUT_ADMIN";

export function replaceAdmin(admin, token) {
  return {
    type: REPLACE_ADMIN,
    admin,
    token,
  };
}

export function logout() {
  return {
    type: LOGOUT_ADMIN,
  };
}

const defaultAdmin = JSON.parse(localStorage.getItem("admin"));

function admin(state = defaultAdmin, action) {
  switch (action.type) {
    case REPLACE_ADMIN:
      localStorage.setItem("admin", JSON.stringify(action.admin));
      localStorage.setItem("admin_token", action.token);
      return action.admin;
    case UPDATE_ADMIN:
      localStorage.setItem("admin", JSON.stringify(action.admin));
      return action.admin;
    case LOGOUT_ADMIN:
      localStorage.removeItem("admin");
      localStorage.removeItem("admin_token");
      window.open("/login", "_self");
      break;
    default:
      return state;
  }
}

export default admin;
