import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import dayjs from "dayjs";
import CustomTable from "../components/CustomTable";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CreateProgram from "../components/CreateProgram";
import ConfimationDialog from "../components/ConfimationDialog";
import {
  getPrograms,
  getProgramById,
  getChallengePacksByProgramId,
  checkProgramDeletableById,
  deleteProgram,
} from "../helper/admin.helper";
import { logout } from "../store/admin";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../components/StyleComponents";

function createData(
  id,
  name,
  programId,
  clientName,
  isActive,
  brandName,
  therapeuticArea,
  startDate,
  endDate,
  createdDate
) {
  return {
    id,
    name,
    programId,
    clientName,
    isActive: isActive === "1" ? "Active" : "Inactive",
    brandName,
    therapeuticArea,
    startDate,
    endDate,
    createdDate,
  };
}

const columns = [
  { id: "programId", align: "left" },
  { id: "name", align: "left" },
  { id: "clientName", align: "left" },
  { id: "isActive", align: "left" },
  { id: "brandName", align: "left" },
  { id: "therapeuticArea", align: "left" },
  { id: "startDate", align: "left" },
  { id: "endDate", align: "left" },
  { id: "createdDate", align: "right" },
];

const headCells = [
  {
    id: "programId",
    numeric: false,
    disablePadding: false,
    label: "Program Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Program Name",
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: false,
    label: "Brand Name",
  },
  {
    id: "therapeuticArea",
    numeric: false,
    disablePadding: false,
    label: "Therapeutic Area",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
  },
  {
    id: "createdDate",
    numeric: true,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

export default function Programs() {
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [editData, setEditData] = useState();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!admin) {
      dispatch(logout());
      return;
    }

    refreshProgram();
  }, [admin]);

  useEffect(() => {
    const filtered = programs.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.clientName &&
          item.clientName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.brandName &&
          item.brandName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredData(filtered);
  }, [searchTerm, programs]);

  const refreshProgram = () => {
    getPrograms().then((response) => {
      if (response.data) {
        const { data } = response;
        const tableData = data.programs.map((p) =>
          createData(
            p.id,
            p.name,
            p.id,
            p.client_name,
            p.is_active,
            p.brand_name,
            p.therapeutic_area,
            formatDate(p.start_date),
            formatDate(p.end_date),
            formatDate(p.created_at)
          )
        );
        setPrograms(tableData);
        setIsCreate(false);
      }
    });
  };

  const handleEdit = (id) => {
    setIsCreate(true);
    setIsEdit(true);
    getProgramById(id).then((response) => {
      if (response.data) {
        const { data } = response;
        setEditData(data.program);
      }
    });
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    checkProgramDeletableById(id).then((response) => {
      if (response.data) {
        const { data } = response;
        if (data.allow) {
          setOpenDialog(true);
        } else {
          setOpen(true);
          setResponse({
            status: false,
            message: `You cannot delete the Program because there is one or more challenges associated with it.`,
          });
        }
      }
    });
  };

  const handleConfirmDelete = () => {
    deleteProgram(deleteId).then(({ data }) => {
      setResponse({
        status: true,
        message: `The Program is deleted successfully!`,
      });
      setOpen(true);
      setOpenDialog(false);
      refreshProgram();
    });
  };

  const handleAddProgram = () => {
    setIsCreate(true);
    setIsEdit(false);
    setEditData(undefined);
  };

  const formatDate = (date) => {
    if (!date || date === "" || date === "0000-00-00 00:00:00") {
      return "";
    }

    return dayjs(date).format("MMMM D, YYYY, h:mm A");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Container maxWidth="unset" sx={{ mt: 4, mb: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={response.status ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {response.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            {isCreate ? (
              <CreateProgram
                onFinish={refreshProgram}
                isEdit={isEdit}
                editData={editData}
              />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Manage Programs</Typography>

                  <Box display="flex" gap={2}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </Search>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddProgram}
                    >
                      Add Program
                    </Button>
                  </Box>
                </Box>
                <CustomTable
                  headCells={headCells}
                  data={filteredData}
                  columns={columns}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      {openDialog && (
        <ConfimationDialog
          title="Are you sure to delete this program?"
          description="Please click 'Confirm' to delete, or 'Cancel' to abort the action."
          onCancel={() => setOpenDialog(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Container>
  );
}
