import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Link,
  Avatar,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SortIcon from "@mui/icons-material/Sort";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import "./App.css";
import Home from "./pages/Dashboard";
import MainListItems from "./components/MainListItems";
import Programs from "./pages/Programs";
import ChallengePacks from "./pages/ChallengePacks";
import Challenges from "./pages/Challenges";
import SiteSettings from "./pages/SiteSettings";
import Login from "./pages/Login";
import { logout } from "./store/admin";
import {
  AppBar,
  Drawer,
  StyledBadge,
  StyledMenu,
} from "./components/StyleComponents";

function App() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [mode, setMode] = useState("light");
  const defaultTheme = createTheme({ palette: { mode } });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                <Box sx={{ display: "flex" }}>
                  <CssBaseline />
                  <AppBar
                    position="absolute"
                    open={open}
                    sx={{
                      bgcolor: "#fff",
                      boxShadow: "0px 4px 5px -2px rgba(0,0,0,0.2)",
                      left: -2,
                    }}
                  >
                    <Toolbar
                      sx={{
                        pr: "24px",
                      }}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                          marginRight: "36px",
                          ...(open && { display: "none" }),
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                      ></Typography>
                      <Box
                        id="demo-customized-button"
                        aria-controls={
                          openMenu ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        sx={{
                          display: { md: "flex", sm: "flex", xs: "flex" },
                          alignItems: "center",
                          justifyContent: "end",
                          color: "#fff",
                          "&:hover": {
                            textDecoration: "underline",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <IconButton color="inherit">
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                          >
                            <Avatar
                              alt="Admin"
                              src="/assets/images/avatar/1.jpg"
                            />
                          </StyledBadge>
                        </IconButton>
                        <Typography
                          variant="h6"
                          noWrap
                          sx={{
                            padding: "7px 5px",
                            fontWeight: 700,
                            fontSize: "14px",
                            color: "#3d3939",
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          Admin
                          <ArrowDropDownOutlinedIcon />
                        </Typography>
                      </Box>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleLogout} disableRipple>
                          <LogoutOutlinedIcon />
                          Logout
                        </MenuItem>
                      </StyledMenu>
                    </Toolbar>
                  </AppBar>
                  <Drawer variant="permanent" open={open}>
                    <Toolbar
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: [1],
                      }}
                    >
                      <IconButton onClick={toggleDrawer}>
                        <SortIcon />
                      </IconButton>
                      <Link href="/">
                        <Box
                          component="img"
                          src="/assets/images/POCN_Logo_TM_285x120.png"
                          href="/"
                          sx={{ width: "140px" }}
                        ></Box>
                      </Link>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                      <MainListItems />
                    </List>
                  </Drawer>
                  <Box
                    component="main"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[100]
                          : theme.palette.grey[900],
                      flexGrow: 1,
                      height: "100vh",
                      overflow: "auto",
                    }}
                  >
                    <Toolbar />
                    <Routes>
                      <Route exact path="/" element={<Home />} />
                      <Route exact path="/programs" element={<Programs />} />
                      <Route
                        exact
                        path="/challenge-packs"
                        element={<ChallengePacks />}
                      />
                      <Route
                        exact
                        path="/challenges"
                        element={<Challenges />}
                      />
                      <Route
                        exact
                        path="/site-configuration"
                        element={<SiteSettings />}
                      />
                    </Routes>
                  </Box>
                </Box>
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
