import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CssBaseline,
  Stack,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  ThemeProvider,
  createTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import LoginInput from "../components/LoginInput";
import { login } from "../helper/admin.helper";
import { replaceAdmin } from "../store/admin";

const theme = createTheme();

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email required"),
  password: Yup.string().required("Password required"),
});

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [response, setResponse] = useState({ status: false, message: "" });

  const handleSubmit = (values, { setSubmitting }) => {
    login(values)
      .then((res) => {
        if (res.data.status) {
          dispatch(replaceAdmin(res.data.admin, res.data.token));
          setResponse({ status: true, message: "You signed in successfully" });
          setOpenAlert(true);
          navigate("/")
        } else {
          setResponse({ status: false, message: res.data.message });
          setOpenAlert(true);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setResponse({
          status: false,
          message: error?.response?.data?.message || error.message,
        });
        setOpenAlert(true);
        setSubmitting(false);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={response.status ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {response.message}
          </Alert>
        </Snackbar>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="img"
              src="/assets/images/POCN_Logo_TM_285x120.png"
            ></Typography>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form style={{ width: "100%" }}>
                  <Stack
                    rowGap={1}
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="baseline"
                  >
                    <LoginInput
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                  </Stack>
                  <Stack
                    rowGap={1}
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="baseline"
                    sx={{ mt: 3 }}
                  >
                    <LoginInput
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                  </Stack>
                  <Link href="#" variant="body" sx={{ float: "right", mt: 3 }}>
                    Forgot password?
                  </Link>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
