import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import dayjs from "dayjs";
import CustomTable from "../components/CustomTable";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CreateChallenge from "../components/CreateChallenge";
import ConfimationDialog from "../components/ConfimationDialog";
import {
  getChallenges,
  getChallengeById,
  deleteChallenge,
  getChallengePacksByFree,
} from "../helper/admin.helper";
import { logout } from "../store/admin";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../components/StyleComponents";

function createData(
  id,
  name,
  challengeId,
  isActive,
  challengeType,
  challengePackName,
  shortUrl,
  createdDate
) {
  return {
    id,
    name,
    challengeId,
    isActive: isActive === "1" ? "Active" : "Inactive",
    challengeType,
    challengePackName: challengePackName !== "null" ? challengePackName : "",
    shortUrl,
    createdDate,
  };
}

const columns = [
  { id: "challengeId", align: "left" },
  { id: "name", align: "left" },
  { id: "isActive", align: "left" },
  { id: "challengeType", align: "left" },
  { id: "challengePackName", align: "left" },
  { id: "shortUrl", align: "left" },
  { id: "createdDate", align: "right" },
];

const headCells = [
  {
    id: "challengeId",
    numeric: false,
    disablePadding: false,
    label: "Challenge Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Challenge Name",
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "challengeType",
    numeric: false,
    disablePadding: false,
    label: "Challenge Type",
  },
  {
    id: "challengePackName",
    numeric: false,
    disablePadding: false,
    label: "Challenge Pack Name",
  },
  {
    id: "shortUrl",
    numeric: false,
    disablePadding: false,
    label: "Short URL",
  },
  {
    id: "createdDate",
    numeric: true,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

export default function Challenges() {
  const [isCreate, setIsCreate] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [response, setResponse] = useState({
    status: false,
    message: "",
  });
  const [challengePacks, setChallengePacks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!admin) {
      dispatch(logout());
      return;
    }

    refreshChallenges();

    getChallengePacksByFree().then((response) => {
      if (response.data) {
        const { data } = response;
        const selectData = data.packs.map((p) => {
          return { id: p.id, name: p.name };
        });

        setChallengePacks(selectData);
      }
    });
  }, [admin]);

  useEffect(() => {
    const filtered = challenges.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.challengeType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.isActive.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.challengePackName && item.challengePackName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredData(filtered);
  }, [searchTerm, challenges]);

  const refreshChallenges = () => {
    getChallenges().then((response) => {
      if (response.data) {
        const { data } = response;
        const tableData = data.challenges.map((p) =>
          createData(
            p.id,
            p.name,
            p.id,
            p.is_active,
            p.type,
            p.challenge_pack_name,
            p.short_url,
            formatDate(p.created_at)
          )
        );
        setIsCreate(false);
        setChallenges(tableData);
      }
    });
  };

  const handleEdit = (id) => {
    setIsCreate(true);
    setIsEdit(true);
    getChallengeById(id).then((response) => {
      if (response.data) {
        const { data } = response;
        setEditData(data.challenge);
      }
    });
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    deleteChallenge(deleteId).then(({ data }) => {
      setResponse({
        status: true,
        message: `The Challenge pack was deleted successfully!`,
      });
      setOpen(true);
      setOpenDialog(false);
      refreshChallenges();
    });
  };

  const handleAddChallange = () => {
    setIsCreate(true);
    setIsEdit(false);
    setEditData(undefined);
  };

  const formatDate = (date) => {
    if (!date || date === "" || date === "0000-00-00 00:00:00") {
      return "";
    }

    return dayjs(date).format("MMMM D, YYYY, h:mm A");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Container maxWidth="unset" sx={{ mt: 4, mb: 4 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={response.status ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {response.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            {isCreate ? (
              <CreateChallenge
                onFinish={refreshChallenges}
                isEdit={isEdit}
                editData={editData}
                freePacks={challengePacks}
              />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Manage Challenges</Typography>
                  <Box display="flex" gap={2}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </Search>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddChallange}
                    >
                      Add Challenge
                    </Button>
                  </Box>
                </Box>
                <CustomTable
                  headCells={headCells}
                  data={filteredData}
                  columns={columns}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      {openDialog && (
        <ConfimationDialog
          title="Are you sure to delete the challenge?"
          description="Please click 'Confirm' to delete, or 'Cancel' to abort the action."
          onCancel={() => setOpenDialog(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Container>
  );
}
